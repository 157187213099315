.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px 5px 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .img {
    flex: none;
    width: 120px;
    height: 90px;
    margin-right: 20px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  > .text {
    max-width: 180px;
    width: 100%;
    flex: none;
    margin: 0 auto 0 0;

    .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .ref {
      display: block;
      font-size: 12px;
      margin-bottom: 3px;
      color: #4b4b4b;
    }

    .location {
      display: flex;
      font-size: 12px;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }

  .info {
    display: grid;
    grid-template-columns: repeat(4, 120px);
    grid-column-gap: 20px;
    margin-right: 30px;

    .block {
      &:first-child {
        .title {
          color: #787878;
        }
        .text {
          color: #787878;
        }
      }

      &:nth-child(3) {
        border-right: 1px solid #f4f4f4;
      }

      &:last-child {
        .title {
          color: #65b500;
        }
        .text {
          color: #787878;
        }
      }

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2px;
      }
      .text {
        display: block;
        font-size: 12px;
        color: #4b4b4b;
      }
    }
  }

  .timer {
    margin-right: 50px;
  }

  .status {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    margin: 0 65px 0 0;
    padding: 5px 10px;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 4px;

    &.green {
      border-color: #65b500;
      background: rgba(101, 181, 0, 0.3);
      color: #65b500;
    }

    &.red {
      border-color: #ab0000;
      background: rgba(171, 0, 0, 0.3);
      color: #ab0000;
    }

    &.blue {
      border-color: #0093d3;
      background: rgba(0, 147, 211, 0.3);
      color: #0093d3;
    }
  }

  .btn {
    max-width: 120px;
    width: 100%;
    flex: none;

    button {
      cursor: pointer;
      background: #e9e9e9;
      border-color: #e9e9e9;
      transition: all 0.3s ease-in-out;
      color: #fff;

      &:hover {
        background: var(--main-color);
      }

      &:first-child {
        margin-right: 25px;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  padding: 5px 0;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .img {
    flex: none;
    width: 100%;
    height: 150px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  > .text {
    width: 100%;
    flex: none;
    margin-bottom: 15px;

    .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .ref {
      display: block;
      font-size: 12px;
      margin-bottom: 3px;
      color: #4b4b4b;
    }

    .location {
      display: flex;
      font-size: 12px;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }

  .info {
    margin-bottom: 15px;

    .block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      &:first-child {
        .title {
          color: #787878;
        }
        .text {
          color: #787878;
        }
      }

      &:last-child {
        margin-bottom: 0;

        .title {
          color: #65b500;
        }
        .text {
          color: #787878;
        }
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 2px;
      }
      .text {
        display: block;
        font-size: 12px;
        order: -1;
        color: #4b4b4b;
      }
    }
  }

  .timer {
    margin-right: 50px;
  }

  .status {
    display: flex;
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    justify-content: center;
    margin-bottom: 15px;
    padding: 5px 10px;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 4px;

    &.green {
      border-color: #65b500;
      background: rgba(101, 181, 0, 0.3);
      color: #65b500;
    }

    &.red {
      border-color: #ab0000;
      background: rgba(171, 0, 0, 0.3);
      color: #ab0000;
    }

    &.blue {
      border-color: #0093d3;
      background: rgba(0, 147, 211, 0.3);
      color: #0093d3;
    }
  }

  .btn {
    width: 100%;
    flex: none;

    button {
      cursor: pointer;
      background: #e9e9e9;
      border-color: #e9e9e9;
      transition: all 0.3s ease-in-out;
      color: #fff;

      &:hover {
        background: var(--main-color);
      }

      &:first-child {
        margin-right: 25px;
      }
    }
  }
}
