.root {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  .deactive {
    position: relative;
    display: flex;
    align-items: center;
    color: var(--main-color-title);

    svg {
      margin-left: 10px;
      path {
        fill: var(--main-color-title);
      }
    }
  }
  .active {
    position: relative;
    display: flex;
    align-items: center;
    svg {
      margin-left: 10px;
      path {
        fill: #cf1616;
      }
    }
  }
}
