.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  transition: all 0.3s ease-in-out;
  z-index: 999;

  .img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    padding: 24px;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow: hidden;

    .close {
      position: absolute;
      top: 150px;
      right: 30px;
      cursor: pointer;
      border: none;
      background: none;
      z-index: 999;
    }
  }
}

/* Mobile */

.rootMobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  overflow-y: scroll;

  .img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    padding: 15px;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background: #fff;

    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      border: none;
      background: none;
      z-index: 999;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}
