.root {
  .list {
    margin-bottom: 50px;
  }
}

/* Mobile */
.rootMobile {
  .list {
    margin-bottom: 30px;
  }
}
