.root {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    .icon {
      flex: none;
      display: flex;
      width: 76px;
      height: 76px;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: #66bee5;

      svg {
        width: 45px;
        height: 45px;

        path {
          stroke: #fff;
        }
      }
    }

    .more {
      flex: none;
      display: flex;
      width: 76px;
      height: 76px;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: #66bee5;

      span {
        width: 10px;
        height: 10px;
        margin-right: 7px;
        border-radius: 50%;
        background: #fff;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .content {
      position: relative;
      width: 100%;
      padding-left: 15px;

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2px;
      }

      .text {
        font-size: 14px;
        font-weight: 400;
        white-space: pre-line;
      }

      .drop {
        position: absolute;
        top: 0;
        right: 0;
        transition: transform 0.3s ease-in-out;
        cursor: pointer;

        &.active {
          transform: rotate(180deg);
        }

        svg {
          path {
            fill: #1e1e1e;
          }
        }
      }
    }
  }

  .stat {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    .left {
      width: 48%;
      .title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2px;
      }

      .text {
        font-size: 12px;
        color: var(--main-color-title);
      }
    }

    .right {
      width: 48%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
      text-align: right;

      .title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2px;
      }

      .price {
        font-size: 12px;
        color: var(--main-color-title);
      }
    }
  }

  .center {
    padding: 15px 0;
    margin-bottom: 15px;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    .block {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        font-size: 14px;
        font-weight: 400;
      }

      .value {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .charat {
    margin-bottom: 30px;

    .title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    .list {
      display: flex;

      .item {
        font-size: 12px;
        padding: 5px 10px;
        margin-right: 8px;
        border-radius: 4px;
        background: #e6f4fb;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .bottom {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    align-items: center;
    color: var(--main-color);

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;

      path {
        fill: var(--main-color);
      }
    }
  }
}
