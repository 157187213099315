.wrapper {
  background: #ffffff;
  min-height: 100vh;
  width: calc(100vw - 20px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .exit {
    position: absolute;
    top: 70px;
    right: 70px;
    cursor: pointer;
  }

  .title {
    font-weight: 800;
    font-size: 48px;
    color: #1e1e1e;
    margin-top: 120px;
  }

  .cards {
    margin-top: 120px;
    display: flex;
    justify-content: center;

    .card {
      margin-right: 20px;
      padding: 32px 32px 24px 32px;
      background: #ffffff;
      border: 2px solid #e9e9e9;
      border-radius: 10px;
      width: 310px;
      position: relative;

      &:nth-last-of-type(1) {
        margin-right: 0;
      }

      .header {
        display: flex;
        align-items: center;

        .icon {
          padding: 10px 11px;
          background: #e6f4fb;
          border-radius: 10px;
        }

        .name {
          margin-left: 16px;
          font-weight: 700;
          font-size: 20px;
          color: #1e1e1e;
        }
      }

      .priceBlock {
        margin-top: 32px;
        display: flex;
        align-items: flex-end;

        .price {
          font-weight: 700;
          font-size: 52px;
          color: #1e1e1e;

          .penny {
            font-size: 26px;
          }

          .priceDescription {
            font-size: 13px;
          }
        }
      }

      .payment {
        font-weight: 400;
        font-size: 14px;
        color: #1e1e1e;
        width: 100%;
        margin-top: 32px;
        padding-bottom: 11px;
        border-bottom: 1px solid #f4f4f4;
      }

      .auctions {
        margin-top: 12px;
        font-weight: 400;
        font-size: 14px;
        color: #1e1e1e;

        .black {
          font-weight: 700;
        }
      }

      .button {
        margin-top: 48px;
      }

      .favorite {
        position: absolute;
        top: -1px;
        right: 20px;
      }
    }
  }
}

.wrapperMobile {
  background: #ffffff;
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;

  .exit {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .title {
    width: 100%;
    font-weight: 800;
    font-size: 35px;
    color: #1e1e1e;
    margin-top: 60px;
  }

  .cards {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .card {
      margin-right: 20px;
      padding: 32px 32px 24px 32px;
      background: #ffffff;
      border: 2px solid #e9e9e9;
      border-radius: 10px;
      width: 100%;
      position: relative;
      margin-bottom: 30px;

      &:nth-last-of-type(1) {
        margin-right: 0;
      }

      .header {
        display: flex;
        align-items: center;

        .icon {
          padding: 10px 11px;
          background: #e6f4fb;
          border-radius: 10px;
        }

        .name {
          margin-left: 16px;
          font-weight: 700;
          font-size: 18px;
          color: #1e1e1e;
        }
      }

      .priceBlock {
        margin-top: 32px;
        display: flex;
        align-items: flex-end;

        .price {
          font-weight: 700;
          font-size: 45px;
          color: #1e1e1e;

          .penny {
            font-size: 22px;
          }

          .priceDescription {
            font-size: 13px;
          }
        }
      }

      .payment {
        font-weight: 400;
        font-size: 14px;
        color: #1e1e1e;
        width: 100%;
        margin-top: 32px;
        padding-bottom: 11px;
        border-bottom: 1px solid #f4f4f4;
      }

      .auctions {
        margin-top: 12px;
        font-weight: 400;
        font-size: 14px;
        color: #1e1e1e;

        .black {
          font-weight: 700;
        }
      }

      .button {
        margin-top: 48px;
      }

      .favorite {
        position: absolute;
        top: -1px;
        right: 20px;
      }
    }
  }
}
