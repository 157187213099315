.root {
	.title {
		font-size: 20px;
		margin-bottom: 30px;
	}

	.list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 20px;
	}
}

/* Mobile */

.rootMobile {
	.title {
		font-size: 18px;
		margin-bottom: 30px;
	}

	.list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 20px;
	}
}
