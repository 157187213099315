.root {
  .ref {
    display: block;
    font-size: 12px;
    margin-bottom: 12px;
    color: #787878;
  }

  .title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .location {
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-bottom: 15px;

    span {
      margin-right: 5px;
    }

    svg {
      flex: none;
      width: 16px;
      height: 19px;
      margin-right: 15px;
    }
  }

  .list {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .item {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }

      .title {
        font-size: 12px;
      }

      .value {
        display: flex;
        font-size: 12px;
        font-weight: 600;
        align-items: center;
        margin-bottom: 15px;

        svg {
          margin-right: 10px;

          path {
            fill: var(--main-color-title);
          }
        }
      }
    }
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;

    .description {
      white-space: pre-line;
    }

    .obser {
      white-space: pre-line;
    }
  }
}

/* Mobile */

.rootMobile {
  .ref {
    display: block;
    font-size: 12px;
    margin-bottom: 10px;
    color: #787878;
  }

  .title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .location {
    display: flex;
    font-size: 12px;
    align-items: center;
    margin-bottom: 15px;

    span {
      margin-right: 5px;
    }

    svg {
      flex: none;
      width: 16px;
      height: 19px;
      margin-right: 15px;
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin-bottom: 20px;

    .item {
      .title {
        font-size: 12px;
      }

      .value {
        display: flex;
        font-size: 12px;
        font-weight: 600;
        align-items: center;

        svg {
          margin-right: 10px;

          path {
            fill: var(--main-color-title);
          }
        }
      }
    }
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;

    .description {
      white-space: pre-line;
    }

    .obser {
      white-space: pre-line;
    }
  }
}
