.root {
	display: flex;
	align-items: center;
	padding: 8px;
	margin-bottom: 15px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
	&:last-child {
		margin-bottom: 0;
	}

	.img {
		max-width: 136px;
		width: 100%;
		height: 136px;
		flex: none;
		margin-right: 30px;
		border-radius: 10px;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.content {
		display: flex;
		width: 100%;
		flex-direction: column;
		padding: 15px 0;

		& > .block {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
		}

		& > .title {
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 5px;
		}

		.link {
			display: block;
			font-size: 12px;
			margin-bottom: 28px;
		}

		.list {
			display: flex;

			.item {
				display: flex;
				flex-direction: column;
				margin-right: 35px;

				.value {
					font-size: 16px;
					font-weight: 600;
				}

				.text {
					font-size: 12px;
					color: var(--main-color-text);
				}
			}
		}
		.status {
			display: flex;
			flex-direction: column;
			padding-left: 25px;
			margin-right: auto;
			border-left: 2px solid #f4f4f4;
			.value {
				font-size: 16px;
				font-weight: 600;
				color: var(--main-color);
			}

			.text {
				font-size: 12px;
				color: var(--main-color-text);
			}
		}

		.timer {
			display: block;
			padding-right: 15px;
		}
	}
}

/* Mobile */

.rootMobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px;
	margin-bottom: 15px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
	&:last-child {
		margin-bottom: 0;
	}

	.img {
		width: 100%;
		height: 136px;
		flex: none;
		border-radius: 10px;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.content {
		display: flex;
		width: 100%;
		flex-direction: column;
		padding: 15px 0;

		& > .block {
			display: grid;
			grid-row-gap: 15px;
		}

		& > .title {
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 5px;
		}

		.link {
			display: block;
			font-size: 12px;
			margin-bottom: 20px;
		}

		.list {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-row-gap: 15px;

			.item {
				display: flex;
				flex-direction: column;
				margin-right: 35px;

				.value {
					font-size: 16px;
					font-weight: 600;
				}

				.text {
					font-size: 12px;
					color: var(--main-color-text);
				}
			}
		}
		.status {
			display: flex;
			flex-direction: column;
			margin-right: auto;
			border-left: 2px solid #f4f4f4;
			.value {
				font-size: 16px;
				font-weight: 600;
				color: var(--main-color);
			}

			.text {
				font-size: 12px;
				color: var(--main-color-text);
			}
		}

		.timer {
			display: block;
			padding-right: 15px;
		}
	}
}
