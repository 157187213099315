.root {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  background: #fff;
  z-index: 9999;

  &.active {
    transform: translateX(0px);
  }
  .list {
    > .item {
      padding: 15px 30px 15px 15px;

      .block {
        position: relative;
        display: flex;
        justify-content: space-between;
      }

      a {
        width: 100%;
        font-size: 600;
        color: var(--main-color-title);
      }

      .btn {
        position: absolute;
        top: 3px;
        right: -20px;
        display: flex;
        justify-content: flex-end;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        svg {
          width: 15px;
          height: 15px;
        }

        &.active {
          transform: rotate(-180deg);
        }
      }

      .drop {
        display: flex;
        width: 100%;
        flex-direction: column;

        &.active {
          /* height: 200px; */
        }

        .item {
          padding: 15px 0;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
