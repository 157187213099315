.wrapper {
  display: flex;
  align-items: center;

  .noCheck {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #0093d3;
  }

  .check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #0093d3;
    border-radius: 4px;
    cursor: pointer;
  }

  .label {
    margin-left: 14px;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
  }
}

/* Mobile */

.wrapperMobile {
  display: flex;
  align-items: center;

  .noCheck {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #0093d3;
  }

  .check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #0093d3;
    border-radius: 4px;
    cursor: pointer;
  }

  .label {
    margin-left: 14px;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
  }
}
