.wrapper {
  display: flex;
  flex-direction: column;
  width: 310px;

  .email {
    border: 1px solid transparent;
    border-radius: 10px;
    &.error {
      border-color: red;
    }
  }

  .password {
    border: 1px solid transparent;
    border-radius: 10px;
    margin-top: 16px;

    .error {
      display: block;
      margin-top: 10px;
      color: red;
    }

    &.error {
      border-color: red;
    }
  }

  .errors {
    font-size: 16px;
    margin-top: 10px;
    color: red;
  }

  .button {
    margin-top: 40px;
  }

  .signIn {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    font-weight: 400;
    font-size: 14px;
    text-decoration-line: underline;
    color: #1e1e1e;
    cursor: pointer;
  }
}

/* Mobile */

.wrapperMobile {
  display: flex;
  flex-direction: column;
  width: 100%;

  .email {
    border: 1px solid transparent;
    border-radius: 10px;
    &.error {
      border-color: red;
    }
  }

  .password {
    border: 1px solid transparent;
    border-radius: 10px;
    margin-top: 16px;

    .error {
      display: block;
      margin-top: 10px;
      color: red;
    }

    &.error {
      border-color: red;
    }
  }

  .errors {
    font-size: 16px;
    margin-top: 10px;
    color: red;
  }

  .button {
    margin-top: 40px;
  }

  .signIn {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    font-weight: 400;
    font-size: 14px;
    text-decoration-line: underline;
    color: #1e1e1e;
    cursor: pointer;
  }
}
