.root {
  .title {
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 120px;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;

    .block {
      position: relative;
      cursor: pointer;

      /* &:hover {
        border-color: var(--main-color);
      } */

      &:nth-child(1) {
        .wrap {
          .img {
            width: 300px;
            height: 300px;
          }
        }
      }

      &:nth-child(2) {
        .wrap {
          .img {
            width: 390px;
            height: 300px;
          }
        }
      }
      .wrap {
        position: relative;
        width: 100%;
        height: 400px;
        margin-bottom: 55px;
        transition: border 0.3s ease-in-out;
        border-radius: 10px;
        border: 2px solid #e9e9e9;

        &:hover {
          border-color: var(--main-color);
        }
        .img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;

          &:nth-child(2) {
            opacity: 0;
            visibility: hidden;
          }
        }

        &:hover {
          .img {
            &:nth-child(1) {
              opacity: 0;
              visibility: hidden;
            }
            &:nth-child(2) {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      .text {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}

/* Mobile */
.rootMobile {
  .title {
    font-size: 25px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 30px;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;

    .block {
      position: relative;
      cursor: pointer;

      /* &:hover {
        border-color: var(--main-color);
      } */

      &:nth-child(1) {
        .wrap {
          .img {
            width: 100px;
            height: 100px;
          }
        }
      }

      &:nth-child(2) {
        .wrap {
          .img {
            width: 100px;
            height: 100px;
          }
        }
      }
      .wrap {
        position: relative;
        width: 100%;
        height: 150px;
        margin-bottom: 30px;
        transition: border 0.3s ease-in-out;
        border-radius: 10px;
        border: 2px solid #e9e9e9;

        &:hover {
          border-color: var(--main-color);
        }
        .img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;

          &:nth-child(2) {
            opacity: 0;
            visibility: hidden;
          }
        }

        &:hover {
          .img {
            &:nth-child(1) {
              opacity: 0;
              visibility: hidden;
            }
            &:nth-child(2) {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      .text {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
