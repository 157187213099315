.wrapper {
  background: #ffffff;
  min-height: 100vh;
  width: calc(100vw - 20px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .exit {
    position: absolute;
    top: 70px;
    right: 70px;
    cursor: pointer;
  }

  .title {
    margin-top: 120px;
    font-weight: 800;
    font-size: 48px;
    color: #1e1e1e;
  }

  .body {
    margin-top: 120px;
    display: flex;

    .block {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &:nth-last-of-type(1) {
        margin-right: 0;
      }

      &:hover {
        .card {
          border-color: #99d4ed;
        }
      }

      .card {
        border: 2px solid #e9e9e9;
        border-radius: 10px;
        padding: 48px 58px;
        transition: 0.3s;
      }

      .cardActive {
        border: 2px solid #99d4ed;
      }

      .name {
        font-weight: 600;
        font-size: 24px;
        color: #1e1e1e;
        margin-top: 56px;
      }
    }
  }
}

/* Mobile */

.wrapperMobile {
  background: #ffffff;
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .exit {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .title {
    margin-top: 60px;
    font-weight: 800;
    font-size: 35px;
    color: #1e1e1e;
  }

  .body {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    .block {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &:nth-last-of-type(1) {
        margin-right: 0;
      }

      &:hover {
        .card {
          border-color: #99d4ed;
        }
      }

      .card {
        border: 2px solid #e9e9e9;
        border-radius: 10px;
        padding: 15px;
        transition: 0.3s;
      }

      .cardActive {
        border: 2px solid #99d4ed;
      }

      .name {
        font-weight: 600;
        font-size: 20px;
        color: #1e1e1e;
        margin: 30px 0;
      }
    }
  }
}
