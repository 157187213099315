.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .list {
    display: flex;

    .item {
      display: flex;
      width: 24px;
      height: 24px;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      color: var(--main-color-title);

      &.active {
        background: var(--main-color);
        color: #fff;
      }
    }
  }

  .prev {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    border: none;
    background: none;
    margin-right: 10px;
    cursor: pointer;
    &:disabled {
      svg {
        path {
          fill: #d2d2d2;
        }
      }
    }
  }
  .next {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    border: none;
    background: none;
    margin-left: 10px;
    cursor: pointer;
    &:disabled {
      svg {
        path {
          fill: #d2d2d2;
        }
      }
    }
  }
}
