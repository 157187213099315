.root {
  position: relative;
  width: 100%;
  flex: none;
  border-radius: 10px;

  .btn {
    display: flex;
    justify-content: flex-end;

    button {
      width: 190px;
    }
  }

  .title {
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 56px;
  }

  .wrap {
    margin-bottom: 56px;
    .group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }

      .input {
        display: flex;
        align-items: flex-end;
        & > div {
          width: 100%;
        }

        .search {
          flex: none;
          width: 48px;
          height: 46px;
          margin-left: 15px;
          border: none;
          border-radius: 10px;
          background: var(--main-color);
          cursor: pointer;
        }
      }
    }

    .textarea {
      textarea {
        height: 200px;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  position: relative;
  width: 100%;
  height: 100%;
  flex: none;
  border-radius: 10px;
  padding: 0 15px;

  .wrap {
    width: 100%;
  }

  .btn {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;

    button {
      width: 190px;
    }
  }

  .title {
    font-size: 35px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 30px;
  }

  .wrap {
    margin-bottom: 30px;
    .group {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      .input {
        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .input {
        display: flex;
        align-items: flex-end;
        & > div {
          width: 100%;
        }

        .search {
          flex: none;
          width: 48px;
          height: 46px;
          margin-left: 15px;
          border: none;
          border-radius: 10px;
          background: var(--main-color);
          cursor: pointer;
        }
      }
    }

    .textarea {
      textarea {
        height: 200px;
      }
    }
  }
}
