.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px 5px 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .wrap {
    display: flex;
    align-items: center;

    .img {
      width: 120px;
      height: 90px;
      margin-right: 20px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    > .text {
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2px;
      }

      .ref {
        font-size: 12px;
        color: #4b4b4b;
      }
    }
  }

  .info {
    display: grid;
    grid-template-columns: repeat(3, 110px);
    grid-column-gap: 25px;
    .block {
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2px;
      }
      .text {
        display: block;
        font-size: 12px;
        color: #4b4b4b;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .status {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 10px;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 4px;

    &.green {
      border-color: #65b500;
      background: rgba(101, 181, 0, 0.3);
      color: #65b500;
    }

    &.yellow {
      border-color: #f2af03;
      background: rgba(242, 175, 3, 0.3);
      color: #f2af03;
    }

    &.red {
      border-color: #ab0000;
      background: rgba(171, 0, 0, 0.3);
      color: #ab0000;
    }

    &.blue {
      border-color: #0093d3;
      background: rgba(0, 147, 211, 0.3);
      color: #0093d3;
    }
  }

  .price {
    text-align: right;
    .title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .text {
      font-size: 12px;
      color: #4b4b4b;
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .wrap {
    display: flex;
    flex-direction: column;

    .img {
      width: 100%;
      height: 150px;
      margin-bottom: 15px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    > .text {
      margin-bottom: 15px;
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2px;
      }

      .ref {
        font-size: 12px;
        color: #4b4b4b;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    .block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 2px;
      }
      .text {
        display: block;
        font-size: 12px;
        order: -1;
        color: #4b4b4b;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .status {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 10px;
    margin-bottom: 15px;
    justify-content: center;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 4px;

    &.green {
      border-color: #65b500;
      background: rgba(101, 181, 0, 0.3);
      color: #65b500;
    }

    &.yellow {
      border-color: #f2af03;
      background: rgba(242, 175, 3, 0.3);
      color: #f2af03;
    }

    &.red {
      border-color: #ab0000;
      background: rgba(171, 0, 0, 0.3);
      color: #ab0000;
    }

    &.blue {
      border-color: #0093d3;
      background: rgba(0, 147, 211, 0.3);
      color: #0093d3;
    }
  }

  .price {
    text-align: left;
    .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .text {
      font-size: 12px;
      color: #4b4b4b;
    }
  }
}
