.root {
  border-bottom: 1px solid #f4f4f4;

  .list {
    display: flex;

    .item {
      font-size: 20px;
      font-weight: 600;
      margin-right: 30px;
      padding-bottom: 15px;
      border-bottom: 4px solid transparent;
      color: var(--main-color-text);
      cursor: pointer;

      &.active {
        border-color: var(--main-color);
        color: #1e1e1e;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  border-bottom: 1px solid #f4f4f4;

  .list {
    display: flex;

    .item {
      font-size: 14px;
      font-weight: 600;
      margin-right: 20px;
      padding-bottom: 10px;
      border-bottom: 2px solid transparent;
      color: var(--main-color-text);
      cursor: pointer;

      &.active {
        border-color: var(--main-color);
        color: #1e1e1e;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
