.root {
  display: flex;
  padding: 25px;
  margin-bottom: 15px;
  border-radius: 10px;
  justify-content: space-between;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .img {
    max-width: 310px;
    width: 100%;
    flex: none;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 510px;
    width: 100%;
    padding-left: 35px;
    margin-right: auto;

    .ref {
      display: block;
      font-size: 12px;
      margin-bottom: 12px;
      color: var(--main-color-text);
    }

    > .title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 5px;
      color: var(--main-color-title);
    }

    .location {
      display: flex;
      font-size: 12px;
      align-items: center;
      margin-bottom: 15px;
      color: var(--main-color-title);
      span {
        margin-right: 5px;
        color: var(--main-color-title);
      }

      svg {
        margin-right: 5px;
      }
    }

    .info {
      margin-bottom: 20px;
      .list {
        display: flex;
        align-items: center;

        .item {
          font-size: 12px;
          margin-right: 25px;

          &:last-child {
            margin-right: 0;
          }
          span {
            display: flex;
            align-items: center;
            color: var(--main-color-title);

            svg {
              margin-right: 10px;
              path {
                fill: var(--main-color-title);
              }
            }
          }
        }
      }
    }

    .price {
      margin-top: auto;
      .list {
        display: flex;

        .item {
          display: flex;
          flex-direction: column;
          margin-right: 30px;
          color: var(--main-color-text);

          .value {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 5px;
            color: var(--main-color-title);

            &.red {
              color: #ab0000;
            }

            &.green {
              color: #65b500;
            }
          }

          .text {
            font-size: 12px;
            color: var(--main-color-title);
          }

          &:nth-child(2) {
            margin-right: 0;
            .value {
              color: var(--main-color-title);
            }
            .text {
              color: var(--main-color-title);
            }
          }
          &:nth-child(3) {
            margin-left: 35px;
            padding-left: 35px;
            border-left: 2px solid #f4f4f4;
          }
        }
      }
    }

    > .text {
      font-size: 12px;
      margin-bottom: 20px;
      color: var(--main-color-title);
    }
  }

  .block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-height: 100%;
    width: 100%;
    .timer {
      display: block;
      margin-bottom: auto;
    }

    .bottom {
      position: relative;
      display: flex;
      justify-content: flex-end;
      margin-top: auto;

      .content {
        display: flex;
        flex-direction: column;
        text-align: right;
        margin-right: 20px;
        justify-content: center;
        .title {
          font-size: 16px;
          font-weight: 400;
        }

        .text {
          font-size: 12px;
          color: #4b4b4b;
        }
      }

      .icon {
        display: flex;
        width: 52px;
        height: 52px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--main-color-title);

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .name {
        display: flex;
        width: 52px;
        height: 52px;
        font-size: 18px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid var(--main-color);
        background: #f2fafd;
        color: var(--main-color);
      }

      .favorite {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-right: 25px;
      }
      .btn {
        max-width: 120px;
        width: 100%;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .img {
    width: 100%;
    height: 200px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .ref {
      display: block;
      font-size: 12px;
      margin-bottom: 12px;
      color: var(--main-color-text);
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
      color: var(--main-color-title);
    }

    .location {
      display: flex;
      font-size: 12px;
      align-items: center;
      margin-bottom: 15px;

      span {
        margin-right: 5px;
      }

      svg {
        margin-right: 5px;
      }
    }

    .info {
      margin-bottom: 20px;
      .list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;

        .item {
          font-size: 12px;
          margin-right: 25px;

          &:last-child {
            margin-right: 0;
          }
          span {
            display: flex;
            align-items: center;
            svg {
              margin-right: 10px;
              path {
                fill: var(--main-color-title);
              }
            }
          }
        }
      }
    }

    .price {
      margin-top: auto;
      .list {
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          color: var(--main-color-text);

          .value {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
            color: var(--main-color-title);

            &.red {
              color: #ab0000;
            }

            &.green {
              color: #65b500;
            }
          }

          .text {
            font-size: 12px;
            color: var(--main-color-title);
          }

          &:nth-child(2) {
            margin-right: 0;
            .value {
              color: var(--main-color-title);
            }
            .text {
              color: var(--main-color-title);
            }
          }
        }
      }
    }

    > .text {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }

  .block {
    display: flex;
    flex-direction: column;
    width: 100%;
    .timer {
      display: block;
      margin-bottom: 30px;
    }

    .bottom {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: auto;

      .content {
        display: flex;
        flex-direction: column;
        text-align: right;
        justify-content: center;
        margin-bottom: 10px;
        .title {
          font-size: 16px;
        }

        .text {
          font-size: 12px;
          color: #4b4b4b;
        }
      }

      .icon {
        display: flex;
        width: 40px;
        height: 40px;
        margin-left: auto;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--main-color-title);

        svg {
          width: 15px;
          height: 15px;
        }
      }

      .name {
        display: flex;
        width: 40px;
        height: 40px;
        font-size: 16px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid var(--main-color);
        background: #f2fafd;
        color: var(--main-color);
      }

      .favorite {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
      }
      .btn {
        width: 100%;
      }
    }
  }
}
