.root {
  .top {
    padding: 20px 30px;
    margin-bottom: 30px;
    border-radius: 20px;
    background: #f4f4f4;

    .list {
      display: flex;

      .item {
        margin-right: 40px;

        &:first-child {
          padding-right: 40px;
          border-right: 1px solid #d2d2d2;
        }

        .title {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 4px;
          color: var(--main-color-text);
        }

        .value {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }

  .chart {
    height: 200px;
    background: #fff;
  }
}

/* Mobile */

.rootMobile {
  .top {
    padding: 15px;
    margin-bottom: 30px;
    border-radius: 20px;
    background: #f4f4f4;

    .list {
      display: flex;

      .item {
        margin-right: 15px;

        &:first-child {
          padding-right: 15px;
          border-right: 1px solid #d2d2d2;
        }

        .title {
          font-size: 10px;
          font-weight: 400;
          margin-bottom: 4px;
          color: var(--main-color-text);
        }

        .value {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }

  .chart {
    background: #fff;
  }
}
