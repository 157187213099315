.root {
  max-width: 860px;
  width: 100%;
  height: 100%;
  .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
    .left {
      max-width: 430px;
      width: 100%;
      padding: 25px;

      .file {
        margin-bottom: 40px;
      }

      .link {
        .title {
          font-size: 16px;
          margin-bottom: 8px;
        }

        .block {
          display: flex;

          .input {
            width: 100%;
            font-size: 16px;
            padding: 12px;
            margin-right: 15px;
            border: 1px solid #d2d2d2;
            border-radius: 10px;
            color: #d2d2d2;

            &::placeholder {
              color: #d2d2d2;
            }
          }

          .add {
            display: flex;
            flex: none;
            width: 48px;
            min-height: 100%;
            align-items: center;
            justify-content: center;
            background: var(--main-color);
            border-radius: 10px;
            border: none;

            svg {
              transform: rotate(-45deg);
              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }

    .right {
      width: 100%;
      padding: 30px;
      border-radius: 0px 10px 10px 0px;
      background: #f2fafd;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        .title {
          font-size: 16px;
          font-weight: 700;
        }

        .length {
          font-size: 12px;
          color: #33a9dc;
        }
      }

      .list {
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 15px;
          margin-bottom: 15px;
          border-bottom: 2px solid #e6f4fb;
          .loader {
            display: flex;
            width: 40px;
            height: 40px;
            font-size: 10px;
            font-weight: 700;
            margin-right: 10px;
            align-items: center;
            justify-content: center;
            border: 2px solid var(--main-color);
            border-radius: 50%;
            color: var(--main-color);
          }

          .acortar {
            display: flex;
            width: 40px;
            height: 40px;
            font-size: 10px;
            font-weight: 700;
            margin-right: 10px;
            align-items: center;
            justify-content: center;
            border: 2px solid transparent;
            border-radius: 50%;
            background: #cce9f6;
            color: var(--main-color);
          }

          .info {
            margin-right: auto;
            .title {
              font-size: 14px;
              font-weight: 600;
              color: var(--main-color);
            }

            .size {
              font-size: 10px;
              color: var(--main-color-text);
            }
          }

          .remove {
            display: block;
            background: transparent;
            cursor: pointer;
            svg {
              width: 20px;
              height: 20px;
              rect {
                fill: transparent;
              }
            }
          }
        }
      }
    }
  }
  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .prev {
      font-size: 16px;
      font-weight: 700;
      color: var(--main-color);
      cursor: pointer;
      svg {
        margin-right: 18px;
        transform: rotate(180deg);
      }
    }

    .next {
      margin-left: auto;
    }
  }
}

/* Mobile */

.rootMobile {
  max-width: 480px;
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
    .left {
      width: 100%;
      padding: 15px;

      .file {
        margin-bottom: 20px;
      }

      .link {
        .title {
          font-size: 14px;
          margin-bottom: 8px;
        }

        .block {
          display: flex;

          .input {
            width: 100%;
            font-size: 14px;
            padding: 12px;
            margin-right: 15px;
            border: 1px solid #d2d2d2;
            border-radius: 10px;
            color: #d2d2d2;

            &::placeholder {
              color: #d2d2d2;
            }
          }

          .add {
            display: flex;
            flex: none;
            width: 48px;
            min-height: 100%;
            align-items: center;
            justify-content: center;
            background: var(--main-color);
            border-radius: 10px;
            border: none;

            svg {
              transform: rotate(-45deg);
              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }

    .right {
      width: 100%;
      padding: 15px;
      border-radius: 0px 10px 10px 0px;
      background: #f2fafd;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        .title {
          font-size: 14px;
          font-weight: 700;
        }

        .length {
          font-size: 12px;
          color: #33a9dc;
        }
      }

      .list {
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 15px;
          margin-bottom: 15px;
          border-bottom: 2px solid #e6f4fb;
          .loader {
            display: flex;
            width: 40px;
            height: 40px;
            font-size: 10px;
            font-weight: 700;
            margin-right: 10px;
            align-items: center;
            justify-content: center;
            border: 2px solid var(--main-color);
            border-radius: 50%;
            color: var(--main-color);
          }

          .acortar {
            display: flex;
            width: 40px;
            height: 40px;
            font-size: 10px;
            font-weight: 700;
            margin-right: 10px;
            align-items: center;
            justify-content: center;
            border: 2px solid transparent;
            border-radius: 50%;
            background: #cce9f6;
            color: var(--main-color);
          }

          .info {
            margin-right: auto;
            .title {
              font-size: 14px;
              font-weight: 600;
              color: var(--main-color);
            }

            .size {
              font-size: 10px;
              color: var(--main-color-text);
            }
          }

          .remove {
            display: block;
            background: transparent;
            cursor: pointer;
            svg {
              width: 20px;
              height: 20px;
              rect {
                fill: transparent;
              }
            }
          }
        }
      }
    }
  }
  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .prev {
      display: flex;
      font-size: 14px;
      font-weight: 700;
      align-items: center;
      margin-bottom: 15px;
      color: var(--main-color);
      cursor: pointer;
      svg {
        margin-right: 10px;
        transform: rotate(180deg);
      }
    }
  }
}
