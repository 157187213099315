.root {
  border-top: 2px solid var(--main-color);

  .top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 1px solid #e9e9e9;
    overflow: hidden;

    .left {
      max-width: 310px;
      width: 100%;
      padding-top: 45px;

      .logo {
        display: block;
        margin-bottom: 30px;
      }

      .text {
        font-size: 12px;
        line-height: 20px;
      }
    }

    .center {
      max-width: 400px;
      width: 100%;
      padding-top: 75px;

      .title {
        font-weight: 700;
        margin-bottom: 25px;
      }

      .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 12px;

        .item {
          font-size: 14px;
          color: var(--main-color-title);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .right {
      padding-top: 75px;

      .title {
        margin-bottom: 25px;
      }

      .social {
        .link {
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
    color: #a5a5a5;
    overflow: hidden;

    .block {
      display: flex;
      align-items: center;

      .img {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  border-top: 2px solid var(--main-color);

  .top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e9e9e9;
    overflow: hidden;

    .left {
      padding-top: 45px;

      .logo {
        display: block;
        margin-bottom: 15px;
      }

      .text {
        font-size: 12px;
        line-height: 20px;
      }
    }

    .center {
      padding-top: 75px;

      .title {
        font-weight: 700;
        margin-bottom: 15px;
      }

      .list {
        .item {
          display: block;
          font-size: 14px;
          margin-bottom: 15px;
          color: var(--main-color-title);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .right {
      padding-top: 10px;

      .title {
        margin-bottom: 15px;
      }

      .social {
        .link {
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    color: #a5a5a5;
    overflow: hidden;

    .block {
      display: flex;
      align-items: center;

      .img {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}
