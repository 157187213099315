.root {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;
  &:last-child {
    margin-bottom: 0;
  }

  .img {
    max-width: 160px;
    width: 100%;
    height: 136px;
    flex: none;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content {
    width: 100%;
    padding-left: 30px;

    & > .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .location {
      display: flex;
      font-size: 12px;
      align-items: center;
      margin-bottom: 25px;

      svg {
        margin-right: 5px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .list {
        display: flex;

        .item {
          display: flex;
          flex-direction: column;
          margin-right: 25px;
          &:nth-child(1) {
            .price {
              color: var(--main-color-text);
            }
          }
          &:nth-child(2) {
            .price {
              color: var(--main-color-title);
            }
          }
          &:last-child {
            margin-right: 0;
          }
          .price {
            font-size: 18px;
            font-weight: 600;
            &.red {
              color: #ab0000;
            }
            &.green {
              color: #65b500;
            }
          }

          .title {
            font-size: 12px;
          }
        }
      }

      .btn {
        width: 120px;

        button {
          font-size: 14px;
          padding: 12px 15px;
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;
  &:last-child {
    margin-bottom: 0;
  }

  .img {
    width: 100%;
    height: 160px;
    flex: none;
    border-radius: 10px;
    margin-bottom: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content {
    width: 100%;

    & > .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .location {
      display: flex;
      font-size: 12px;
      align-items: center;
      margin-bottom: 15px;

      svg {
        margin-right: 5px;
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      .list {
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
          &:nth-child(1) {
            .price {
              color: var(--main-color-text);
            }
          }
          &:nth-child(2) {
            .price {
              color: var(--main-color-title);
            }
          }
          &:last-child {
            margin-right: 0;
          }
          .price {
            font-size: 18px;
            font-weight: 600;
            &.red {
              color: #ab0000;
            }
            &.green {
              color: #65b500;
            }
          }

          .title {
            font-size: 12px;
          }
        }
      }

      .btn {
        display: flex;
        width: 100%;
        justify-content: center;

        button {
          font-size: 14px;
          padding: 12px 15px;
        }
      }
    }
  }
}
