.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  transition: all 0.3s ease-in-out;
  z-index: 999;

  .img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content {
    position: absolute;
    max-width: 860px;
    width: 100%;
    height: 505px;
    top: 50%;
    left: 50%;
    padding: 24px;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow: hidden;

    .img {
      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 44px;
        height: 44px;
        border: none;
        border-radius: 50%;
        background: #1e1e1e;
        z-index: 5;
        cursor: pointer;
        opacity: 0.4;

        &::after {
          content: '';
          position: absolute;
          top: 14px;
          left: 21px;
          width: 2px;
          height: 17px;
          transform: rotate(45deg);
          background: #fff;
        }

        &::before {
          content: '';
          position: absolute;
          top: 14px;
          right: 21px;
          width: 2px;
          height: 17px;
          transform: rotate(-45deg);
          background: #fff;
        }
      }
    }
  }
}
