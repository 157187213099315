.root {
  .list {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      font-size: 14px;
      padding: 15px 0;
      justify-content: space-between;
      &:nth-child(even) {
        background: #f4f4f4;
      }
      .text {
        font-weight: 600;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  .list {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      font-size: 12px;
      padding: 15px 0;
      justify-content: space-between;
      &:nth-child(even) {
        background: #f4f4f4;
      }
      .text {
        font-weight: 600;
      }
    }
  }
}
