.root {
  .top {
    margin-bottom: 40px;
  }

  .switch {
    margin-bottom: 30px;
  }
}

/* Mobile */

.rootMobile {
  .top {
    margin-bottom: 30px;
  }

  .switch {
    margin-bottom: 30px;
  }
}
