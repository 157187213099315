.wrapper {
  border-radius: 10px;
  padding: 12px 40px;
  width: 100%;
  cursor: pointer;
  &.blue {
    background: #0093d3;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    border: 1px solid #0093d3;
  }
  &.gray {
    background: #efecec;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    border: 1px solid #efecec;
  }
}
.wrapperMobile {
  border-radius: 10px;
  padding: 12px 40px;
  width: 100%;
  cursor: pointer;
  &.blue {
    background: #0093d3;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    border: 1px solid #0093d3;
  }

  &.gray {
    background: #efecec;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    border: 1px solid #efecec;
  }
}
