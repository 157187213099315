.root {
  max-width: 860px;
  width: 100%;
  height: 100%;
  .form {
    margin-bottom: 75px;
    .group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .list {
      display: flex;
      align-items: center;

      .item {
        margin-right: 40px;
        cursor: pointer;
      }
    }

    .wrap {
      margin-bottom: 25px;
    }

    .bottom {
      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .prev {
      font-size: 16px;
      font-weight: 700;
      color: var(--main-color);
      cursor: pointer;
      svg {
        margin-right: 18px;
        transform: rotate(180deg);
      }
    }

    .next {
      margin-left: auto;
    }
  }
}

/* Mobile */

.rootMobile {
  max-width: 480px;
  width: 100%;
  height: 100%;
  padding: 0 15px;

  .form {
    margin-bottom: 30px;
    .group {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      .input {
        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .list {
      display: flex;
      align-items: center;

      .item {
        margin-right: 40px;
        cursor: pointer;
      }
    }

    .wrap {
      margin-bottom: 25px;
    }

    .bottom {
      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
  }

  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .prev {
      display: flex;
      font-size: 14px;
      font-weight: 700;
      align-items: center;
      margin-bottom: 15px;
      color: var(--main-color);
      cursor: pointer;
      svg {
        margin-right: 10px;
        transform: rotate(180deg);
      }
    }
  }
}
