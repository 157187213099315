.root {
  .title {
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 40px;

    span {
      font-weight: 600;
    }
  }

  .slider {
    margin-bottom: 55px;
  }

  .center {
    display: flex;
    margin-bottom: 55px;

    .rates {
      max-width: 750px;
      width: 100%;
      flex: none;
      margin-right: 20px;
    }

    .deposit {
      width: 100%;
    }
  }

  .block {
    margin-bottom: 55px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;

    .left {
      max-width: 860px;
      width: 100%;
      flex: none;
      margin-right: 20px;
    }

    .right {
      width: 100%;
    }
  }
}

/* Mobile */

.rootMobile {
  .title {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;

    span {
      font-weight: 600;
    }
  }

  .slider {
    margin-bottom: 30px;
  }

  .center {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .rates {
      width: 100%;
      flex: none;
      margin-bottom: 30px;
    }

    .deposit {
      width: 100%;
    }
  }

  .block {
    margin-bottom: 30px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;

    .left {
      max-width: 860px;
      width: 100%;
      flex: none;
      margin-right: 20px;
    }

    .right {
      width: 100%;
    }
  }
}
