.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;

	.title {
		font-size: 36px;
		font-weight: 600;

		span {
			font-weight: 300;
		}
	}

	.block {
		display: flex;
		align-items: center;
		button {
			display: flex;
			font-size: 18px;
			align-items: center;
			padding: 12px 16px;
			border: none;
			border-radius: 10px;
			background: none;
			color: #fff;
			cursor: pointer;

			svg {
				width: 22px;
				height: 22px;
				margin-left: 30px;
				rect {
					fill: none;
				}

				path {
					fill: #fff;
				}
			}
		}
		.edit {
			margin-right: 30px;
			background: #4b4b4b;
		}

		.cancel {
			margin-right: 30px;
			background: #ab0000;
		}

		.send {
			background: var(--main-color);
		}
	}
}

.root {
	display: flex;
	justify-content: space-between;

	.left {
		max-width: 875px;
		width: 100%;
		flex: none;

		.gallery {
			margin-bottom: 50px;
		}

		.wrap {
			display: flex;
			justify-content: space-between;

			.block {
				max-width: 530px;
				width: 100%;

				.switch {
					.list {
						position: relative;
						display: flex;
						margin-bottom: 30px;

						&::after {
							content: '';
							position: absolute;
							display: block;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 2px;
							background: #f4f4f4;
							z-index: 1;
						}

						.item {
							position: relative;
							font-size: 16px;
							font-weight: 600;
							margin-right: 25px;
							padding-bottom: 15px;
							border: none;
							border-bottom: 4px solid transparent;
							background: none;
							color: var(--main-color-text);
							cursor: pointer;
							z-index: 2;

							&.active {
								border-color: var(--main-color);
								color: var(--main-color-title);
							}

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}

			.map {
				padding-left: 20px;
			}
		}
	}

	.right {
		width: 100%;
		padding-left: 20px;

		.timer {
			margin-bottom: 30px;
		}

		.status {
			margin-bottom: 15px;
		}

		.history {
			margin-bottom: 15px;
		}

		.control {
			margin-bottom: 15px;
		}
	}
}

/* Mobile */
.topMobile {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;

	.title {
		font-size: 25px;
		font-weight: 600;
		margin-bottom: 15px;

		span {
			font-weight: 300;
		}
	}

	.block {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 15px;

		button {
			display: flex;
			font-size: 10px;
			align-items: center;
			justify-content: space-between;
			padding: 10px;
			border: none;
			border-radius: 10px;
			background: none;
			color: #fff;
			cursor: pointer;

			svg {
				width: 15px;
				height: 15px;
				margin-left: 10px;
				rect {
					fill: none;
				}

				path {
					fill: #fff;
				}
			}
		}
		.edit {
			background: #4b4b4b;
		}

		.cancel {
			background: #ab0000;
		}

		.send {
			background: var(--main-color);
		}
	}
}
.rootMobile {
	display: flex;
	flex-direction: column;
	.left {
		width: 100%;
		flex: none;

		.gallery {
			margin-bottom: 30px;
		}

		.wrap {
			display: flex;
			flex-direction: column;

			.block {
				width: 100%;

				.switch {
					.list {
						position: relative;
						display: flex;
						margin-bottom: 30px;

						&::after {
							content: '';
							position: absolute;
							display: block;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 2px;
							background: #f4f4f4;
							z-index: 1;
						}

						.item {
							position: relative;
							font-size: 14px;
							font-weight: 600;
							margin-right: 25px;
							padding-bottom: 15px;
							border: none;
							border-bottom: 4px solid transparent;
							background: none;
							color: var(--main-color-text);
							cursor: pointer;
							z-index: 2;

							&.active {
								border-color: var(--main-color);
								color: var(--main-color-title);
							}

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}

	.right {
		width: 100%;

		.timer {
			margin-bottom: 30px;
		}
	}
}
