.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .list {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
  }
}

/* Mobile */

.rootMobile {
  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }
}
