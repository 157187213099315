.root {
  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 25px;
  }
  label {
    pointer-events: none;
  }
  .block {
    display: flex;
    width: 100%;
    height: 275px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #cce9f6;

    .input {
      display: none;
    }
    svg {
      margin-bottom: 35px;
    }

    .text {
      font-size: 12px;
      color: var(--main-color-text);
      margin-bottom: 5px;
    }

    .link {
      font-size: 12px;
      text-decoration: underline;
      color: var(--main-color);
    }
  }
}

/* Mobile */

.rootMobile {
  .title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 25px;
  }
  label {
    pointer-events: none;
  }
  .block {
    display: flex;
    width: 100%;
    height: 180px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    border: 1px dashed #cce9f6;

    .input {
      display: none;
    }
    svg {
      margin-bottom: 35px;
    }

    .text {
      font-size: 12px;
      color: var(--main-color-text);
      margin-bottom: 5px;
    }

    .link {
      font-size: 12px;
      text-decoration: underline;
      color: var(--main-color);
    }
  }
}
