.root {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .title {
      font-size: 36px;
      font-weight: 600;
    }

    .btn {
      max-width: 250px;
      width: 100%;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
}

/* Mobile */

.rootMobile {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .title {
      font-size: 25px;
      font-weight: 600;
      margin-right: 10px;
    }

    .btn {
      width: 150px;

      button {
        font-size: 12px;
        padding: 12px 10px;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
}
