.wrapper {
  display: flex;
  flex-direction: column;

  .label {
    font-weight: 400;
    font-size: 16px;
    color: #1e1e1e;
  }

  .select {
    font-weight: 400;
    font-size: 16px;
    color: #1e1e1e;
    margin-top: 8px;
    padding: 12px 16px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    width: 100%;
    position: relative;
    &.error {
      border-color: red;
    }

    .main {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .placeholder {
        color: #d2d2d2;
        font-weight: 400;
        font-size: 16px;
      }

      .selected {
        color: #1e1e1e;
        font-weight: 400;
        font-size: 16px;
      }

      .arrow {
      }
    }

    .list {
      position: absolute;
      display: flex;
      flex-direction: column;
      border: 1px solid #d2d2d2;
      border-top: none;
      padding: 12px 16px;
      background: #ffffff;
      border-radius: 0 0 10px 10px;
      width: calc(100% + 2px);
      left: -1px;
      z-index: 1;

      &.scroll {
        height: 200px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: var(--main-color);
        }
      }

      .item {
        cursor: pointer;
        transition: 0.3s;
        padding: 5px 0;

        &:nth-of-type(1) {
          padding-top: 0;
        }

        &:hover {
          color: #505050;
        }
      }
    }
  }
}

/* Mobile */

.wrapperMobile {
  display: flex;
  flex-direction: column;

  .label {
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
  }

  .select {
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-top: 8px;
    padding: 12px 16px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    width: 100%;
    position: relative;
    &.error {
      border-color: red;
    }
    .main {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .placeholder {
        color: #d2d2d2;
        font-weight: 400;
        font-size: 14px;
      }

      .selected {
        color: #1e1e1e;
        font-weight: 400;
        font-size: 14px;
      }

      .arrow {
      }
    }

    .list {
      position: absolute;
      display: flex;
      flex-direction: column;
      border: 1px solid #d2d2d2;
      border-top: none;
      padding: 12px 16px;
      background: #ffffff;
      border-radius: 0 0 10px 10px;
      width: calc(100% + 2px);
      left: -1px;
      z-index: 1;

      &.scroll {
        height: 200px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: var(--main-color);
        }
      }
      .item {
        cursor: pointer;
        transition: 0.3s;
        padding: 5px 0;

        &:nth-of-type(1) {
          padding-top: 0;
        }

        &:hover {
          color: #505050;
        }
      }
    }
  }
}
