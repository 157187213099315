.root {
  max-width: 880px;
  width: 100%;

  .title {
    font-size: 48px;
    text-align: center;
    margin-bottom: 55px;
  }

  .text {
    width: 100%;
    height: 525px;
    padding: 30px;
    margin-bottom: 25px;
    border-radius: 10px;
    background: #f4f4f4;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 30px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #e9e9e9;
    }
  }

  .checkbox {
    font-size: 16px;
    margin-bottom: 55px;
    cursor: pointer;
  }

  .bottom {
    display: flex;
    justify-content: center;
  }
}

/* Mobile */

.rootMobile {
  max-width: 480px;
  width: 100%;
  padding: 0 15px;

  .title {
    font-size: 25px;
    text-align: center;
    margin-bottom: 30px;
  }

  .text {
    width: 100%;
    height: 525px;
    font-size: 14px;
    padding: 15px;
    margin-bottom: 25px;
    border-radius: 10px;
    background: #f4f4f4;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 30px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #e9e9e9;
    }
  }

  .checkbox {
    font-size: 16px;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .bottom {
    display: flex;
    justify-content: center;
  }
}
