.root {
  max-width: 860px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 30px;
  }

  .img {
    margin-bottom: 30px;
  }

  .text {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .subtitle {
    font-weight: 800;
    margin-bottom: 55px;
  }

  .bottom {
    .btn {
      margin-bottom: 30px;
    }

    .link {
      display: block;
      font-weight: 700;
      text-align: center;
      text-decoration: underline;
      color: var(--main-color);
    }
  }
}

/* Mobile */

.rootMobile {
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;

  .title {
    font-size: 25px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 30px;
  }

  .img {
    max-width: 300px;
    width: 100%;
    margin-bottom: 30px;
  }

  .text {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .subtitle {
    font-weight: 800;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .bottom {
    .btn {
      margin-bottom: 15px;
    }

    .link {
      display: block;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      text-decoration: underline;
      color: var(--main-color);
    }
  }
}
