.root {
  .slider {
    margin-bottom: 50px;
  }

  .validate {
    margin-bottom: 50px;
  }

  .latest {
    margin-bottom: 50px;
  }
}

/* Mobile */

.rootMobile {
  .slider {
    margin-bottom: 30px;
  }

  .validate {
    margin-bottom: 30px;
  }

  .latest {
    margin-bottom: 30px;
  }
}
