.root {
  .img {
    position: relative;
    display: block;
    width: 100%;
    height: 380px;
    border-radius: 10px;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .prev {
      display: flex;
      position: absolute;
      top: 50%;
      left: 0;
      width: 40px;
      height: 40px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      z-index: 5;
      cursor: pointer;
      overflow: hidden;
      svg {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #1e1e1e;
        opacity: 0.4;
      }
    }

    .next {
      display: flex;
      position: absolute;
      top: 50%;
      right: 0;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      z-index: 5;
      cursor: pointer;
      overflow: hidden;

      svg {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #1e1e1e;
        opacity: 0.4;
      }
    }
  }

  .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: 96px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;

    .overlay {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #1e1e1e;
        opacity: 0.3;
      }

      & .text {
        display: block;
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 8px;
        text-transform: uppercase;
        padding: 2px;
        background: #fff;
        border-radius: 5px;
        color: var(--main-color-text);
        z-index: 2;
      }

      .play {
        position: relative;
        z-index: 3;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  .img {
    position: relative;
    display: block;
    width: 100%;
    height: 200px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .prev {
      display: flex;
      position: absolute;
      top: 50%;
      left: 0;
      width: 40px;
      height: 40px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      z-index: 5;
      cursor: pointer;
      overflow: hidden;
      svg {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #1e1e1e;
        opacity: 0.4;
      }
    }

    .next {
      display: flex;
      position: absolute;
      top: 50%;
      right: 0;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      z-index: 5;
      cursor: pointer;
      overflow: hidden;

      svg {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #1e1e1e;
        opacity: 0.4;
      }
    }
  }

  .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: 70px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;

    .overlay {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #1e1e1e;
        opacity: 0.3;
      }

      & .text {
        display: block;
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 8px;
        text-transform: uppercase;
        padding: 2px;
        background: #fff;
        border-radius: 5px;
        color: var(--main-color-text);
        z-index: 2;
      }

      .play {
        position: relative;
        z-index: 3;
      }
    }
  }
}
