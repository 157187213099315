.root {
  display: flex;
  justify-content: space-between;
  padding: 15px 45px 15px 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .left {
    display: flex;
    align-items: center;

    .icon {
      display: flex;
      width: 72px;
      height: 72px;
      align-items: center;
      justify-content: center;
      margin-right: 25px;
      border-radius: 10px;
      background: #99d4ed;
    }

    .content {
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      .location {
        font-size: 12px;
        color: #4b4b4b;

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .status {
      font-size: 18px;
      font-weight: 600;
      margin-right: 40px;

      .green {
        color: #65b500;
      }

      .red {
        color: #ab0000;
      }
    }

    .switch {
      position: relative;
      width: 26px;
      margin-right: 100px;
      cursor: pointer;

      .line {
        display: block;
        width: 100%;
        height: 8px;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        background: #a5a5a5;
      }

      .circle {
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
        transition: all 0.3s ease;
        border-radius: 50%;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        background: #fff;
      }

      &.active {
        .circle {
          left: auto;
          right: 0;
          background: #99d4ed;
        }

        .line {
          background: #99d4ed;
        }
      }
    }

    .edit {
      button {
        border: none;
        background: none;
        cursor: pointer;

        &:first-child {
          margin-right: 25px;
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: space-between;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .left {
    display: flex;
    width: 100%;
    flex: none;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .icon {
      display: flex;
      flex: none;
      width: 35px;
      height: 35px;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      border-radius: 10px;
      background: #99d4ed;

      svg {
        width: 15px;
        height: 15px;
      }
    }

    .content {
      width: 100%;
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      .location {
        font-size: 12px;
        color: #4b4b4b;

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .right {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;

    .status {
      max-width: 100px;
      width: 100%;
      font-size: 12px;
      font-weight: 600;

      .green {
        color: #65b500;
      }

      .red {
        color: #ab0000;
      }
    }

    .switch {
      position: relative;
      width: 26px;
      margin: 0 auto;
      cursor: pointer;

      .line {
        display: block;
        width: 100%;
        height: 8px;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        background: #a5a5a5;
      }

      .circle {
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
        transition: all 0.3s ease;
        border-radius: 50%;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        background: #fff;
      }

      &.active {
        .circle {
          left: auto;
          right: 0;
          background: #99d4ed;
        }

        .line {
          background: #99d4ed;
        }
      }
    }

    .edit {
      display: flex;
      max-width: 100px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      button {
        border: none;
        background: none;
        cursor: pointer;

        &:first-child {
          margin: 0 15px 0 auto;
        }
      }
    }
  }
}
