.root {
  display: flex;
  &.small {
    .block {
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid #f4f4f4;

      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border: none;
      }

      .value {
        font-size: 24px;
        color: var(--main-color-title);
      }

      .text {
        font-size: 8px;
        text-transform: uppercase;
      }
    }
  }

  &.big {
    .block {
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #f4f4f4;

      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border: none;
      }

      .value {
        font-size: 36px;
        font-weight: 400;
        color: var(--main-color-title);
      }

      .text {
        font-size: 8px;
        text-transform: uppercase;
      }
    }
  }
}

.rootMobile {
  display: flex;
  &.small {
    .block {
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid #f4f4f4;

      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border: none;
      }

      .value {
        font-size: 18px;
        color: var(--main-color-title);
      }

      .text {
        font-size: 8px;
        text-transform: uppercase;
      }
    }
  }

  &.big {
    .block {
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #f4f4f4;

      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border: none;
      }

      .value {
        font-size: 24px;
        font-weight: 400;
        color: var(--main-color-title);
      }

      .text {
        font-size: 8px;
        text-transform: uppercase;
      }
    }
  }
}
