.root {
  .list {
    position: relative;
    display: flex;
    z-index: 2;
    > .item {
      position: relative;
      display: flex;
      font-size: 16px;
      margin-right: 30px;
      padding-bottom: 15px;
      text-decoration: none;
      transition: border 0.3s ease-in-out;
      border-bottom: 2px solid transparent;
      color: var(--main-color-title);

      &.drop {
        margin-right: 60px;
      }

      .icon {
        position: absolute;
        top: -2px;
        right: -22px;
        transition: all 0.3s ease-in-out;
        svg {
          path {
            fill: var(--main-color-title);
          }
        }
      }

      &:hover {
        border-color: var(--main-color);

        .icon {
          top: 0;
          transform: rotate(180deg);
        }

        .drop {
          opacity: 1;
          visibility: visible;
        }
      }

      &.active {
        font-weight: 700;
        border-color: var(--main-color);
      }

      & .drop {
        display: flex;
        position: absolute;
        width: 250px;
        top: 115%;
        left: 0;
        flex-direction: column;
        padding: 0 25px 0 25px;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;
        border: 1px solid #f4f4f4;
        background: #fff;
        opacity: 0;
        visibility: hidden;

        .link {
          position: relative;
          display: block;
          padding: 15px 0;
          border-bottom: 1px solid #f4f4f4;
          color: var(--main-color-title);

          svg {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%) rotate(-90deg);

            path {
              fill: var(--main-color-title);
            }
          }
        }
      }
    }
  }
}
