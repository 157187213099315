.root {
	padding: 25px;
	border-radius: 10px;
	background: #fff;

	> .title {
		font-size: 20px;
		margin-bottom: 25px;
	}

	.wrap {
		display: flex;
		justify-content: space-between;

		.block {
			width: 48%;

			&:first-child {
				text-align: left;
			}

			&:last-child {
				text-align: right;
			}
			.value {
				display: block;
				font-size: 18px;
				font-weight: 600;
			}

			.text {
				display: block;
				font-size: 14px;
				color: #4b4b4b;
			}
		}
	}
}

/* Mobile */

.rootMobile {
	padding: 15px 5px;
	border-radius: 10px;
	background: #fff;

	> .title {
		font-size: 18px;
		margin-bottom: 25px;
	}

	.wrap {
		display: flex;
		justify-content: space-between;

		.block {
			width: 48%;

			&:first-child {
				text-align: left;
			}

			&:last-child {
				text-align: right;
			}
			.value {
				display: block;
				font-size: 16px;
				font-weight: 600;
			}

			.text {
				display: block;
				font-size: 12px;
				color: #4b4b4b;
			}
		}
	}
}
