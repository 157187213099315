.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 480px;
  padding: 30px 30px 0 30px;
  border-radius: 10px;
  background: #fff;

  .chart {
    position: absolute;
    width: 100%;
    height: 300px;
    left: 0;
    bottom: 50px;
    padding: 0 20px;
  }

  .list {
    display: flex;
    padding-bottom: 25px;
    border-bottom: 1px solid #f4f4f4;
    .item {
      display: flex;
      flex-direction: column;
      margin-right: 55px;

      .title {
        font-size: 12px;
        margin-bottom: 5px;
        color: var(--main-color-text);
      }

      .value {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 15px 5px;
  border-radius: 10px;
  background: #fff;

  .chart {
    width: 100%;
    height: 200px;
    padding: 15px 0;
  }

  .list {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #f4f4f4;
    .item {
      display: flex;
      flex-direction: column;
      margin-right: 30px;

      .title {
        font-size: 10px;
        margin-bottom: 5px;
        color: var(--main-color-text);
      }

      .value {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
