.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  transition: all 0.3s ease-in-out;
  z-index: 999;

  .img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content {
    position: absolute;
    max-width: 860px;
    width: 100%;
    height: 505px;
    top: 50%;
    left: 50%;
    padding: 24px;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow: hidden;

    &.active {
      .video {
        .overlay {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:hover {
      .video {
        .overlay {
          opacity: 1;
          visibility: visible;
        }
      }

      .img {
        .overlay {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .video {
      position: relative;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0.3;
          background: #1e1e1e;
        }
      }
      .play {
        position: absolute;
        left: 52%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background: none;
        z-index: 8;
        cursor: pointer;

        &::after {
          content: '';
          display: block;
          border: 25px solid transparent;
          border-left: 40px solid #fff;
        }
      }

      .pause {
        display: flex;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 20px;
        justify-content: space-between;
        transform: translate(-50%, -50%);
        border: none;
        background: none;
        z-index: 8;
        cursor: pointer;

        &::after {
          content: '';
          display: block;
          width: 5px;
          height: 50px;
          background: #fff;
        }
        &::before {
          content: '';
          display: block;
          width: 5px;
          height: 50px;
          background: #fff;
        }
      }

      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 44px;
        height: 44px;
        border: none;
        border-radius: 50%;
        background: #1e1e1e;
        opacity: 0.4;
        z-index: 5;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          top: 14px;
          left: 21px;
          width: 2px;
          height: 17px;
          transform: rotate(45deg);
          background: #fff;
        }

        &::before {
          content: '';
          position: absolute;
          top: 14px;
          right: 21px;
          width: 2px;
          height: 17px;
          transform: rotate(-45deg);
          background: #fff;
        }
      }

      .thumb {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        z-index: 1;
      }
    }
  }
}
