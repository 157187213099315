.root {
  max-width: 1000px;
  width: 100%;

  > .title {
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 55px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;

    .left {
      max-width: 425px;
      width: 100%;
      flex: none;

      .img {
        display: block;
        width: 100%;
        height: 205px;
        margin-bottom: 30px;
        border-radius: 10px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .subtitle {
        font-size: 16px;
        margin-bottom: 30px;
      }

      .list {
        .item {
          display: flex;
          font-size: 14px;
          justify-content: space-between;
          padding: 10px;

          &:nth-child(odd) {
            background: #f4f4f4;
          }

          .value {
            font-weight: 600;
          }
        }
      }
    }

    .right {
      width: 100%;
      padding-left: 40px;

      .ref {
        font-size: 12px;
        margin-bottom: 5px;
        color: var(--main-color-text);
      }

      .name {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .location {
        display: flex;
        width: 100%;
        align-items: flex-start;
        margin-bottom: 30px;

        .address {
          width: 100%;
          line-height: 1.4;
          > span {
            margin-right: 3px;
          }
        }

        svg {
          flex: none;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }

      .block {
        display: flex;
        margin-bottom: 40px;
        align-items: center;

        .date {
          display: flex;
          flex-direction: column;
          margin-right: 50px;

          .value {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 4px;
          }

          .text {
            font-size: 12px;
          }
        }

        .price {
          display: flex;
          flex-direction: column;
          margin-left: auto;

          .value {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 4px;
          }

          .text {
            font-size: 12px;
          }
        }
      }

      .description {
        white-space: pre-line;
        margin-bottom: 30px;

        .title {
          font-size: 16px;
          margin-bottom: 15px;
        }

        .text {
          font-size: 14px;
        }
      }

      .documents {
        .title {
          font-size: 16px;
          margin-bottom: 18px;
        }
        .list {
          .item {
            display: flex;
            font-size: 14px;
            align-items: center;
            padding-bottom: 18px;
            margin-bottom: 18px;
            border-bottom: 1px solid #e9e9e9;

            &:last-child {
              border: none;
            }
            svg {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;

    .link {
      display: flex;
      font-size: 16px;
      font-weight: 700;
      align-items: center;
      color: var(--main-color);
      cursor: pointer;
      svg {
        margin-right: 20px;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  width: 100%;
  padding: 0 15px;

  > .title {
    font-size: 25px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 30px;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .left {
      width: 100%;
      flex: none;
      margin-bottom: 15px;

      .img {
        display: block;
        width: 100%;
        height: 205px;
        margin-bottom: 30px;
        border-radius: 10px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .subtitle {
        font-size: 14px;
        margin-bottom: 15px;
      }

      .list {
        .item {
          display: flex;
          font-size: 12px;
          justify-content: space-between;
          padding: 10px;

          &:nth-child(odd) {
            background: #f4f4f4;
          }

          .value {
            font-weight: 600;
          }
        }
      }
    }

    .right {
      width: 100%;

      .ref {
        font-size: 12px;
        margin-bottom: 5px;
        color: var(--main-color-text);
      }

      .name {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .location {
        display: flex;
        width: 100%;
        font-size: 14px;
        align-items: flex-start;
        margin-bottom: 15px;

        .address {
          width: 100%;
          line-height: 1.4;
          > span {
            margin-right: 3px;
          }
        }

        svg {
          flex: none;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }

      .block {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .date {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;

          .value {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 4px;
          }

          .text {
            font-size: 12px;
          }
        }

        .price {
          display: flex;
          flex-direction: column;

          .value {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 4px;
          }

          .text {
            font-size: 12px;
          }
        }
      }

      .description {
        white-space: pre-line;
        margin-bottom: 15px;

        .title {
          font-size: 16px;
          margin-bottom: 15px;
        }

        .text {
          font-size: 14px;
        }
      }

      .documents {
        .title {
          font-size: 16px;
          margin-bottom: 18px;
        }
        .list {
          .item {
            display: flex;
            font-size: 14px;
            align-items: center;
            padding-bottom: 18px;
            margin-bottom: 18px;
            border-bottom: 1px solid #e9e9e9;

            &:last-child {
              border: none;
            }
            svg {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .link {
      display: flex;
      font-size: 14px;
      font-weight: 700;
      align-items: center;
      margin-bottom: 15px;
      color: var(--main-color);
      cursor: pointer;
      svg {
        margin-right: 10px;
      }
    }
  }
}
