.root {
  background: #ffffff;
  width: calc(100vw - 20px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .exit {
    position: absolute;
    top: 70px;
    right: 70px;
    cursor: pointer;
  }

  .title {
    font-weight: 800;
    font-size: 48px;
    color: #1e1e1e;
    margin-top: 120px;
  }

  .body {
    display: flex;
    justify-content: space-between;
    margin-top: 56px;

    .left {
      width: calc(1080px - 420px - 20px);

      .discountCode {
        display: flex;
        flex-direction: column;

        .titleDiscountCode {
          font-weight: 600;
          font-size: 24px;
          color: #1e1e1e;
        }

        .field {
          margin-top: 32px;
          display: flex;
          align-items: center;
          width: 100%;

          .input {
            width: 100%;
          }

          .button {
            margin-left: 16px;
          }
        }
      }

      .information {
        display: flex;
        flex-direction: column;
        margin-top: 48px;

        .titleInformation {
          font-weight: 600;
          font-size: 24px;
          color: #1e1e1e;
        }

        .person {
          margin-top: 32px;
          display: flex;
          width: 100%;

          .name {
            width: 100%;
          }

          .lastName {
            width: 100%;
            margin-left: 20px;
          }
        }

        .country {
          margin-top: 24px;
        }

        .address {
          margin-top: 24px;
        }

        .addressFields {
          margin-top: 24px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 20px;
          grid-row-gap: 24px;
        }

        .checkBox {
          margin-top: 40px;
          margin-bottom: 120px;
        }
      }
    }

    .right {
      margin-left: 20px;
      width: 420px;

      .order {
        background: #fbfbfb;
        border-radius: 10px;
        padding: 24px;
        display: flex;
        flex-direction: column;

        .titleOrder {
          font-weight: 600;
          font-size: 24px;
          color: #1e1e1e;
        }

        .products {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          padding-bottom: 24px;
          border-bottom: 2px solid #e9e9e9;

          .productName {
            font-weight: 400;
            font-size: 16px;
            color: #1e1e1e;
          }

          .productPrice {
            font-weight: 400;
            font-size: 16px;
            color: #1e1e1e;
          }
        }

        .subtotal {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;

          .subtotalName {
            font-weight: 400;
            font-size: 16px;
            color: #1e1e1e;
          }

          .subtotalPrice {
            font-weight: 400;
            font-size: 16px;
            color: #1e1e1e;
          }
        }

        .total {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;

          .totalName {
            font-weight: 700;
            font-size: 16px;
            color: #1e1e1e;
          }

          .totalPrice {
            font-weight: 700;
            font-size: 16px;
            color: #1e1e1e;
          }
        }

        .iva {
          margin-top: 4px;
          font-weight: 300;
          font-size: 12px;
          text-align: right;
          color: #1e1e1e;
        }
      }

      .card {
        display: flex;
        flex-direction: column;
        background: #fbfbfb;
        border-radius: 10px;
        padding: 24px;

        .cardTitle {
          font-weight: 600;
          font-size: 24px;
          color: #1e1e1e;
        }

        .cardDescription {
          font-weight: 400;
          font-size: 12px;
          color: #787878;
          margin-top: 8px;
        }

        .cardFields {
          margin-top: 24px;
          display: grid;
          width: 100%;
          grid-row-gap: 24px;

          .stripeField {
            label {
              font-weight: 400;
              font-size: 16px;
              color: #1e1e1e;
            }

            .stripeInput {
              background: #ffffff;
              border: 1px solid #d2d2d2;
              border-radius: 10px;
              padding: 12px 16px;
              margin-top: 8px;
            }
          }

          .button {
            padding: 12px 24px 0 24px;
          }

          .errorStripe {
            color: red;
            text-align: center;
          }
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  background: #ffffff;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .exit {
    position: absolute;
    top: 70px;
    right: 70px;
    cursor: pointer;
  }

  .title {
    font-weight: 800;
    font-size: 32px;
    color: #1e1e1e;
    margin-top: 60px;
  }

  .body {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .left {
      width: 100%;

      .discountCode {
        display: flex;
        flex-direction: column;

        .titleDiscountCode {
          font-weight: 600;
          font-size: 20px;
          color: #1e1e1e;
        }

        .field {
          margin-top: 15px;
          display: flex;
          align-items: center;
          width: 100%;
          flex-direction: column;

          .input {
            width: 100%;
            margin-bottom: 15px;
          }

          .button {
            width: 100%;
          }
        }
      }

      .information {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        .titleInformation {
          font-weight: 600;
          font-size: 20px;
          color: #1e1e1e;
        }

        .person {
          margin-top: 15px;
          display: flex;
          width: 100%;
          flex-direction: column;

          .name {
            width: 100%;
            margin-bottom: 15px;
          }

          .lastName {
            width: 100%;
          }
        }

        .country {
          margin-top: 15px;
        }

        .address {
          margin-top: 15px;
        }

        .addressFields {
          display: grid;
          grid-template-columns: 1fr;
          grid-row-gap: 15px;
          margin-top: 15px;
        }

        .checkBox {
          margin: 30px 0;
        }
      }
    }

    .right {
      width: 100%;

      .order {
        background: #fbfbfb;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        .titleOrder {
          font-weight: 600;
          font-size: 20px;
          color: #1e1e1e;
        }

        .products {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          padding-bottom: 24px;
          border-bottom: 2px solid #e9e9e9;

          .productName {
            font-weight: 400;
            font-size: 14px;
            color: #1e1e1e;
          }

          .productPrice {
            font-weight: 400;
            font-size: 14px;
            color: #1e1e1e;
          }
        }

        .subtotal {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;

          .subtotalName {
            font-weight: 400;
            font-size: 14px;
            color: #1e1e1e;
          }

          .subtotalPrice {
            font-weight: 400;
            font-size: 14px;
            color: #1e1e1e;
          }
        }

        .total {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;

          .totalName {
            font-weight: 700;
            font-size: 14px;
            color: #1e1e1e;
          }

          .totalPrice {
            font-weight: 700;
            font-size: 14px;
            color: #1e1e1e;
          }
        }

        .iva {
          margin-top: 4px;
          font-weight: 300;
          font-size: 12px;
          text-align: right;
          color: #1e1e1e;
        }
      }

      .card {
        display: flex;
        flex-direction: column;
        background: #fbfbfb;
        border-radius: 10px;

        .cardTitle {
          font-weight: 600;
          font-size: 20px;
          color: #1e1e1e;
        }

        .cardDescription {
          font-weight: 400;
          font-size: 12px;
          color: #787878;
          margin-top: 8px;
        }

        .cardFields {
          margin-top: 24px;
          display: grid;
          width: 100%;
          grid-row-gap: 24px;

          .stripeField {
            label {
              font-weight: 400;
              font-size: 16px;
              color: #1e1e1e;
            }

            .stripeInput {
              background: #ffffff;
              border: 1px solid #d2d2d2;
              border-radius: 10px;
              padding: 12px 16px;
              margin-top: 8px;
            }
          }

          .button {
            margin-bottom: 30px;
          }

          .errorStripe {
            color: red;
            text-align: center;
          }
        }
      }
    }
  }
}
