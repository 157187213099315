.root {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  padding: 100px 0;

  .top {
    max-width: 860px;
    width: 100%;
    height: 100%;

    .title {
      font-size: 48px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 55px;
    }

    .list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      padding: 0 50px;
      margin-bottom: 55px;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
          font-size: 16px;
          font-weight: 700;
          color: #d2d2d2;
        }

        .status {
          position: relative;
          width: 28px;
          height: 28px;
          margin: 25px 0;
          border: 8px solid #e9e9e9;
          border-radius: 50%;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 20px;
            width: 125px;
            height: 4px;
            transform: translateY(-50%);
            background: #e9e9e9;
          }
        }

        &:last-child {
          .status {
            &::after {
              content: '';
              display: none;
            }
          }
        }

        &:first-child {
          .status {
            &::before {
              content: '';
              display: none;
            }
          }
        }

        .loader {
          position: absolute;
          top: 50%;
          left: 50%;
          display: flex;
          width: 36px;
          height: 36px;
          padding: 5px;
          align-items: center;
          justify-content: center;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: var(--main-color);
          z-index: 2;
        }

        .check {
          position: absolute;
          top: 50%;
          left: 50%;
          display: flex;
          width: 36px;
          height: 36px;
          padding: 5px;
          align-items: center;
          justify-content: center;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: var(--main-color-title);
          z-index: 2;
        }

        &.ready {
          .text {
            color: var(--main-color-title);
          }

          .icon {
            svg {
              path {
                fill: var(--main-color-title);
              }
            }
          }

          .status {
            &::after {
              background: #4b4b4b;
            }
          }
        }

        &.active {
          .text {
            color: var(--main-color);
          }

          .icon {
            svg {
              path {
                fill: var(--main-color);
              }
            }
          }

          .status {
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              right: 20px;
              width: 125px;
              height: 4px;
              transform: translateY(-50%);
              background: linear-gradient(90deg, #4b4b4b 0%, #0093d3 100%);
            }
          }
        }
      }
    }

    .form {
      margin-bottom: 75px;
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .prev {
        font-size: 16px;
        font-weight: 700;
        color: var(--main-color);
        cursor: pointer;
        svg {
          margin-right: 18px;
          transform: rotate(180deg);
        }
      }

      .next {
        margin-left: auto;
      }
    }
  }

  .exit {
    position: absolute;
    top: 68px;
    right: 77px;
  }
}

/* Mobile */

.rootMobile {
  position: relative;
  display: flex;
  max-width: 480px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  min-height: 100%;

  .top {
    width: 100%;
    height: 100%;

    .title {
      font-size: 25px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 30px;
    }

    .list {
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      margin-bottom: 30px;

      .item {
        display: flex;
        max-width: 50px;
        width: 100%;
        flex-direction: column;
        align-items: center;

        &:first-child {
          .check {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: -100px;
              display: block;
              width: 100px;
              height: 20px;
              background: #fff;
            }
          }
        }

        &:last-child {
          .status {
            span {
              position: relative;

              &::before {
                content: '';
                position: absolute;
                top: -7px;
                right: -112px;
                display: block;
                width: 100px;
                height: 20px;
                background: #fff;
              }
            }
          }
          .check {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              right: -100px;
              display: block;
              width: 100px;
              height: 20px;
              background: #fff;
            }
          }
        }

        .text {
          font-size: 10px;
          font-weight: 700;
          color: #d2d2d2;
        }

        .status {
          position: relative;
          width: 20px;
          height: 20px;
          margin: 15px 0;
          border: 8px solid #e9e9e9;
          border-radius: 50%;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 80px;
            height: 4px;
            transform: translateY(-50%);
            background: #e9e9e9;
          }
        }

        &:last-child {
          .status {
            &::after {
              content: '';
              display: none;
            }
          }
        }

        &:first-child {
          .status {
            &::before {
              content: '';
              display: none;
            }
          }
        }

        .loader {
          position: absolute;
          top: 50%;
          left: 50%;
          display: flex;
          width: 20px;
          height: 20px;
          padding: 5px;
          align-items: center;
          justify-content: center;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: var(--main-color);
          z-index: 2;
        }

        .check {
          position: absolute;
          top: 50%;
          left: 50%;
          display: flex;
          width: 20px;
          height: 20px;
          padding: 5px;
          align-items: center;
          justify-content: center;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: var(--main-color-title);
          z-index: 2;

          svg {
            width: 15px;
            height: 15px;
          }
        }

        .icon {
          svg {
            width: 30px;
            height: 30px;
          }
        }
        &.ready {
          .text {
            color: var(--main-color-title);
          }

          .icon {
            svg {
              width: 30px;
              height: 30px;
              path {
                fill: var(--main-color-title);
              }
            }
          }

          .status {
            &::after {
              background: #4b4b4b;
            }
          }
        }

        &.active {
          .text {
            color: var(--main-color);
          }

          .icon {
            svg {
              width: 30px;
              height: 30px;
              path {
                fill: var(--main-color);
              }
            }
          }

          .status {
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              width: 125px;
              height: 4px;
              transform: translateY(-50%);
              background: linear-gradient(90deg, #4b4b4b 0%, #0093d3 100%);
            }
          }
        }
      }
    }

    .form {
      margin-bottom: 75px;
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .prev {
        font-size: 16px;
        font-weight: 700;
        color: var(--main-color);
        cursor: pointer;
        svg {
          margin-right: 18px;
          transform: rotate(180deg);
        }
      }

      .next {
        margin-left: auto;
      }
    }
  }

  .exit {
    position: absolute;
    top: 15px;
    right: 15px;

    svg {
      width: 25px;
      height: 25px;
    }
  }
}
