.wrapper {
  background: #ffffff;
  min-height: 100vh;
  width: calc(100vw - 20px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .exit {
    position: absolute;
    top: 70px;
    right: 70px;
    cursor: pointer;
  }

  .title {
    font-weight: 800;
    font-size: 48px;
    color: #1e1e1e;
    margin-top: 120px;
  }

  .image {
    margin-top: 56px;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1e1e1e;
    margin-top: 60px;
  }

  .description {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1e1e1e;
  }

  .button {
    margin-top: 56px;
  }
}

/* Mobile */

.wrapperMobile {
  background: #ffffff;
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;

  .exit {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .title {
    font-weight: 800;
    font-size: 25px;
    text-align: center;
    color: #1e1e1e;
    margin-top: 60px;
  }

  .image {
    max-width: 250px;
    width: 100%;
    margin: 56px auto 0 auto;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1e1e1e;
    margin-top: 30px;
  }

  .description {
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1e1e1e;
  }

  .button {
    margin-top: 30px;
  }
}
