.root {
  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
  }
}

/* Mobile */

.rootMobile {
  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .list {
    display: flex;
    flex-direction: column;
  }
}
