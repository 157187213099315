.root {
  .title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}
