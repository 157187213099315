.root {
  display: flex;
  width: 100%;
  padding: 25px;
  border-radius: 10px;
  background: #fff;

  .icon {
    display: flex;
    flex: none;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 10px;
    background: #66bee5;
  }

  .content {
    width: 100%;
    .category {
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: #a5a5a5;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    > .text {
      font-size: 12px;
      margin-bottom: 15px;
    }

    .price {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      .value {
        font-size: 20px;
        font-weight: 700;
        margin-right: 8px;
      }

      .text {
        font-size: 10px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;

        .lock {
          margin-right: 25px;
          cursor: pointer;
        }

        .remove {
          cursor: pointer;
        }
      }

      .right {
        display: flex;
        font-size: 16px;
        font-weight: 700;
        align-items: center;
        color: var(--main-color);
        cursor: pointer;

        svg {
          margin-left: 10px;
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  .icon {
    display: flex;
    flex: none;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 10px;
    background: #66bee5;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .content {
    width: 100%;

    .category {
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: #a5a5a5;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    > .text {
      font-size: 12px;
      margin-bottom: 15px;
    }

    .price {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .value {
        font-size: 16px;
        font-weight: 700;
        margin-right: 8px;
      }

      .text {
        font-size: 10px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;

        .lock {
          margin-right: 25px;
          cursor: pointer;
        }

        .remove {
          cursor: pointer;
        }
      }

      .right {
        display: flex;
        font-size: 14px;
        font-weight: 700;
        align-items: center;
        color: var(--main-color);
        cursor: pointer;

        svg {
          margin-left: 10px;
        }
      }
    }
  }
}
