.root {
  .title {
    font-size: 36px;
    margin-bottom: 40px;
  }

  .switch {
    margin-bottom: 30px;
  }
}

/* Mobile */

.rootMobile {
  .title {
    font-size: 25px;
    margin-bottom: 30px;
  }

  .switch {
    margin-bottom: 15px;
  }
}
