.root {
  .top {
    margin-bottom: 40px;
    .title {
      font-size: 36px;
      font-weight: 600;
    }
  }

  .switch {
    margin-bottom: 30px;
  }
}

/* Mobile */

.rootMobile {
  .top {
    margin-bottom: 40px;
    .title {
      font-size: 36px;
      font-weight: 600;
    }
  }

  .switch {
    margin-bottom: 30px;
  }
}
