.wrapper {
	background: #ffffff;
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;

	.title {
		margin-top: 120px;
	}

	.form {
		max-width: 860px;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 25px;
		margin-top: 56px;
		padding: 0 25px;
	}

	.button {
		margin-top: 30px;
	}
}

/* Mobile */

.wrapperMobile {
	background: #ffffff;
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;

	.exit {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	}

	.title {
		margin-top: 60px;
	}

	.form {
		width: 100%;
		display: grid;
		grid-row-gap: 15px;
		margin-top: 30px;
		padding: 0 25px;
	}

	.button {
		margin-top: 30px;
	}
}
