.wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  position: relative;

  .left {
    min-width: 250px;
    width: 55%;
    background-repeat: no-repeat;
    background-size: 100% auto;

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 200px;

      .activeButton {
        background: #ffffff;
        border-radius: 20px 0 0 20px;
        padding: 12px 20px;
        border: none;
        margin-bottom: 12px;
        color: #1e1e1e;
        font-weight: 600;
        font-size: 24px;
      }

      .inactiveButton {
        color: #ffffff;
        margin-bottom: 12px;
        padding: 12px 20px;
        background: none;
        border: none;
        font-weight: 600;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  .right {
    width: 45%;
    min-width: 250px;
    padding: 126px 0 0 134px;

    .header {
      display: flex;
      flex-direction: column;

      .logo {
      }

      .title {
        white-space: pre-line;
        font-weight: 800;
        font-size: 48px;
        line-height: 52px;
        color: #1e1e1e;
        margin-top: 45px;
      }

      .forgotDescription {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1e1e1e;
        white-space: pre-line;
        margin-top: 56px;
      }
    }

    .form {
      margin-top: 30px;
    }
  }

  .modal {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.wrapperMobile {
  height: 100%;
  .left {
    display: none;
  }

  .right {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .logo {
        margin-bottom: 30px;
      }
    }

    .title {
      font-size: 28px;
      text-align: center;
      margin-bottom: 30px;
    }

    .forgotDescription {
      text-align: center;
      margin-bottom: 30px;
    }
  }
}
