.root {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: #fff;
  overflow-y: scroll;
  padding-bottom: 20px;

  .content {
    max-width: 860px;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-weight: 800;
      font-size: 48px;
      margin-bottom: 100px;
      line-height: 52px;
      text-align: center;
      color: #1e1e1e;
    }

    .btn {
      display: flex;

      button {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .close {
    position: absolute;
    top: 75px;
    right: 75px;
    border: none;
    background: none;
    cursor: pointer;
  }
}
