.root {
  display: block;
  padding: 25px 10px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);

  > .img {
    width: 100%;
    height: 160px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content {
    padding: 0 15px;

    .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .location {
      display: flex;
      font-size: 12px;
      align-items: center;
      margin-bottom: 20px;

      svg {
        margin-right: 5px;
      }
    }

    .price {
      display: flex;
      margin-bottom: 30px;

      .start {
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        color: var(--main-color-text);

        .value {
          font-size: 18px;
          font-weight: 600;
        }

        .text {
          font-size: 12px;
        }
      }

      .end {
        display: flex;
        flex-direction: column;
        color: var(--main-color-title);
        .value {
          font-size: 18px;
          font-weight: 600;
        }

        .text {
          font-size: 12px;
        }
      }
    }

    .info {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .block {
        display: flex;
        font-size: 12px;
        align-items: center;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }

        svg {
          margin-right: 5px;
        }
      }
    }

    .user {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .icon {
        flex: none;
        display: flex;
        width: 24px;
        height: 25px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #000;

        svg {
          width: 10px;
          height: 10px;
        }
      }

      .img {
        flex: none;
        display: flex;
        width: 24px;
        height: 25px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .content {
        font-size: 12px;

        span {
          margin-right: 5px;
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .link {
        display: flex;
        font-size: 16px;
        font-weight: 700;
        align-items: center;
        color: #0093d3;

        svg {
          margin-left: 10px;
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: block;
  padding: 15px 5px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);

  > .img {
    width: 100%;
    height: 160px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content {
    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .location {
      display: flex;
      font-size: 10px;
      align-items: center;
      margin-bottom: 10px;

      svg {
        margin-right: 5px;
      }
    }

    .price {
      display: flex;
      margin-bottom: 15px;

      .start {
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        color: var(--main-color-text);

        .value {
          font-size: 16px;
          font-weight: 600;
        }

        .text {
          font-size: 10px;
        }
      }

      .end {
        display: flex;
        flex-direction: column;
        color: var(--main-color-title);
        .value {
          font-size: 16px;
          font-weight: 600;
        }

        .text {
          font-size: 10px;
        }
      }
    }

    .info {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .block {
        display: flex;
        font-size: 12px;
        align-items: center;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }

        svg {
          margin-right: 5px;
        }
      }
    }

    .user {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .icon {
        flex: none;
        display: flex;
        width: 24px;
        height: 25px;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #000;

        svg {
          width: 10px;
          height: 10px;
        }
      }

      .img {
        flex: none;
        display: flex;
        width: 24px;
        height: 25px;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .content {
        font-size: 12px;

        span {
          margin-right: 5px;
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .link {
        display: flex;
        font-size: 14px;
        font-weight: 700;
        align-items: center;
        color: #0093d3;

        svg {
          margin-left: 10px;
        }
      }
    }
  }
}
