.root {
  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;

    a {
      display: inline-flex;
      font-size: 16px;
      align-items: center;
      margin-left: 10px;
      color: var(--main-color);

      svg {
        margin-left: 10px;
      }
    }
  }

  .list {
    display: block;
  }
}

/* Mobile */

.rootMobile {
  .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;

    a {
      display: inline-flex;
      font-size: 16px;
      align-items: center;
      margin-left: 10px;
      color: var(--main-color);

      svg {
        margin-left: 10px;
      }
    }
  }

  .list {
    display: block;
  }
}
