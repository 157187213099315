.root {
  position: relative;
  .select {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    background: #ffffff;
    color: #787878;
    z-index: 1;
    cursor: pointer;
    .icon {
      position: absolute;
      top: 8px;
      right: 15px;
      transition: all 0.3s ease-in-out;

      &.active {
        top: 10px;
        transform: rotate(180deg);
      }
    }
  }

  .list {
    position: absolute;
    width: 100%;
    top: 80%;
    left: 0;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    border: 1px solid #d2d2d2;
    border-top: none;
    z-index: 2;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .item {
      padding: 10px 16px;
      cursor: pointer;
    }
  }
}

/* Mobile */

.rootMobile {
  position: relative;
  .select {
    position: relative;
    display: flex;
    font-size: 12px;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    background: #ffffff;
    color: #787878;
    z-index: 1;
    cursor: pointer;
    .icon {
      position: absolute;
      top: 8px;
      right: 10px;
      transition: all 0.3s ease-in-out;

      &.active {
        top: 10px;
        transform: rotate(180deg);
      }
    }
  }

  .list {
    position: absolute;
    width: 100%;
    top: 80%;
    left: 0;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    border: 1px solid #d2d2d2;
    border-top: none;
    z-index: 2;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .item {
      font-size: 12px;
      padding: 10px 16px;
      cursor: pointer;
    }
  }
}
