.wrapper {
  position: relative;

  .input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .label {
    display: flex;
    flex-direction: column;

    .fakeLabel {
      font-weight: 400;
      font-size: 16px;
      color: #1e1e1e;
    }

    .fakeBox {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .placeholder {
        width: 100%;
        background: #ffffff;
        border: 1px solid #d2d2d2;
        border-radius: 10px;
        padding: 12px 16px;

        font-weight: 400;
        font-size: 16px;
        color: #d2d2d2;
        cursor: pointer;

        &.error {
          border-color: red;
        }
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #0093d3;
        border-radius: 10px;
        padding: 12px;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
}

/* Mobile */

.wrapperMobile {
  position: relative;

  .input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .label {
    display: flex;
    flex-direction: column;

    .fakeLabel {
      font-weight: 400;
      font-size: 14px;
      color: #1e1e1e;
    }

    .fakeBox {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .placeholder {
        width: 100%;
        background: #ffffff;
        border: 1px solid #d2d2d2;
        border-radius: 10px;
        padding: 12px 16px;

        font-weight: 400;
        font-size: 14px;
        color: #d2d2d2;
        cursor: pointer;
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #0093d3;
        border-radius: 10px;
        padding: 12px;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
}
