.root {
  .title {
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 40px;

    span {
      font-weight: 600;
    }
  }
}

/* Mobile */

.rootMobile {
  .title {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 30px;

    span {
      font-weight: 600;
    }
  }
}
