.root {
	.top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;

		.icon {
			position: relative;
			display: flex;
			width: 160px;
			height: 160px;
			font-size: 40px;
			margin-right: 55px;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			border: 1px solid var(--main-color);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			color: var(--main-color);
			> svg {
				width: 60px;
				height: 60px;
			}

			.status {
				display: flex;
				position: absolute;
				right: 2px;
				bottom: 2px;
				width: 32px;
				height: 32px;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				border: 3px solid #fff;
				background: var(--main-color);

				> svg {
					width: 18px;
					height: 18px;
				}
			}
		}

		.content {
			margin-right: auto;
			.nick {
				display: flex;
				font-size: 16px;
				align-items: center;
				margin-bottom: 15px;

				svg {
					margin-right: 12px;

					path {
						fill: var(--main-color);
					}
				}
			}

			> .title {
				font-size: 24px;
				font-weight: 600;
				margin-bottom: 4px;
			}

			.cif {
				font-size: 16px;
				margin-bottom: 30px;
				color: #a5a5a5;
			}

			.info {
				display: flex;

				.block {
					font-weight: 600;
					margin-right: 40px;

					.value {
						display: block;
						font-size: 18px;
						margin-bottom: 4px;

						span {
							margin-right: 5px;
						}
					}

					.title {
						font-size: 16px;
						font-weight: 400;
						color: #a5a5a5;
					}
				}
			}
		}

		.send {
			display: flex;
			width: 40px;
			height: 40px;
			margin-top: 15px;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background: #f4f4f4;
		}
	}

	.switch {
		margin-bottom: 60px;
	}
}

/* Mobile */

.rootMobile {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.top {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;

		.icon {
			position: relative;
			display: flex;
			width: 100px;
			height: 100px;
			font-size: 25px;
			margin-bottom: 15px;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			border: 1px solid var(--main-color);
			color: var(--main-color);
			> svg {
				width: 60px;
				height: 60px;
			}

			.status {
				display: flex;
				position: absolute;
				right: 2px;
				bottom: 2px;
				width: 32px;
				height: 32px;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				border: 3px solid #fff;
				background: var(--main-color);

				> svg {
					width: 18px;
					height: 18px;
				}
			}
		}

		.content {
			width: 100%;
			.nick {
				display: flex;
				font-size: 14px;
				align-items: center;
				margin-bottom: 15px;

				svg {
					margin-right: 12px;

					path {
						fill: var(--main-color);
					}
				}
			}

			> .title {
				font-size: 18px;
				font-weight: 600;
				margin-bottom: 4px;
			}

			.cif {
				font-size: 14px;
				margin-bottom: 15px;
				color: #a5a5a5;
			}

			.info {
				display: flex;
				flex-direction: column;
				.block {
					font-weight: 600;
					margin-bottom: 15px;

					.value {
						display: block;
						font-size: 16px;
						margin-bottom: 4px;

						span {
							margin-right: 5px;
						}
					}

					.title {
						font-size: 14px;
						font-weight: 400;
						color: #a5a5a5;
					}
				}
			}
		}

		.send {
			display: flex;
			width: 40px;
			height: 40px;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background: #f4f4f4;
		}
	}

	.switch {
		margin-bottom: 30px;
	}
}
