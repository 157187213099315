.root {
  max-width: 860px;
  width: 100%;
  height: 100%;
  .form {
    margin-bottom: 75px;
    .group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .prev {
      font-size: 16px;
      font-weight: 700;
      color: var(--main-color);
      cursor: pointer;
      svg {
        margin-right: 18px;
        transform: rotate(180deg);
      }
    }

    .next {
      margin-left: auto;
    }
  }

  .top {
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 55px;
    border-radius: 10px;
    background: rgba(171, 0, 0, 0.3);

    .left {
      display: flex;
      align-items: center;

      svg {
        margin-right: 15px;
      }

      .text {
        font-weight: 600;
        color: #ab0000;
      }
    }

    .right {
      display: flex;
      font-weight: 700;
      align-items: center;

      a {
        text-decoration: underline;
        color: var(--main-color-title);
      }
    }
  }
}

/* Mobile */

.rootMobile {
  max-width: 860px;
  width: 100%;
  height: 100%;
  padding: 0 15px;

  .form {
    margin-bottom: 30px;
    .group {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      .input {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .prev {
      font-size: 16px;
      font-weight: 700;
      color: var(--main-color);
      cursor: pointer;
      svg {
        margin-right: 18px;
        transform: rotate(180deg);
      }
    }
  }

  .top {
    display: flex;
    font-size: 14px;
    flex-direction: column;
    padding: 15px;
    margin-bottom: 30px;
    border-radius: 10px;
    background: rgba(171, 0, 0, 0.3);

    .left {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      svg {
        margin-right: 15px;
      }

      .text {
        display: block;
        font-weight: 600;
        color: #ab0000;
      }
    }

    .right {
      font-weight: 700;

      a {
        display: block;
        text-align: center;
        text-decoration: underline;
        color: var(--main-color-title);
      }
    }
  }
}
