.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px 5px 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .left {
    display: flex;
    align-items: center;
    .img {
      flex: none;
      width: 120px;
      height: 90px;
      margin-right: 20px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    > .text {
      max-width: 180px;
      width: 100%;
      flex: none;
      margin: 0 auto 0 0;

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .ref {
        display: block;
        font-size: 12px;
        margin-bottom: 3px;
        color: #4b4b4b;
      }

      .location {
        display: flex;
        font-size: 12px;
        align-items: center;

        svg {
          margin-right: 5px;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    .info {
      display: flex;
      justify-content: space-between;
      .block {
        max-width: 120px;
        width: 100%;
        flex: none;
        margin-right: 30px;

        &:first-child {
          .title {
            color: #787878;
          }
          .text {
            color: #787878;
          }
        }

        &:nth-child(3) {
          border-right: 1px solid #f4f4f4;
        }

        &:last-child {
          .title {
            color: #65b500;
          }
          .text {
            color: #787878;
          }
        }

        .title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 2px;
        }
        .text {
          display: block;
          font-size: 12px;
          color: #4b4b4b;
        }
      }

      .user {
        display: flex;
        flex: none;
        width: 250px;
        align-items: center;

        .prof {
          display: flex;
          width: 44px;
          height: 44px;
          margin-right: 20px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: #000;

          svg {
            width: 18px;
            height: 18px;
          }
        }

        .part {
          display: flex;
          width: 44px;
          height: 44px;
          font-size: 16px;
          margin-right: 20px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 2px solid var(--main-color);
          color: var(--main-color);
          background: #F2FAFD;

          svg {
            width: 18px;
            height: 18px;
          }
        }

        .content {
          .title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 2px;
          }

          .text {
            font-size: 12px;
          }
        }
      }
    }

    .status {
      display: flex;
      width: 110px;
      font-size: 12px;
      font-weight: 700;
      margin-left: 100px;
      padding: 5px 10px;
      justify-content: center;
      text-transform: uppercase;
      border: 1px solid transparent;
      border-radius: 4px;

      &.green {
        border-color: #65b500;
        background: rgba(101, 181, 0, 0.3);
        color: #65b500;
      }

      &.red {
        border-color: #ab0000;
        background: rgba(171, 0, 0, 0.3);
        color: #ab0000;
      }

      &.blue {
        border-color: #0093d3;
        background: rgba(0, 147, 211, 0.3);
        color: #0093d3;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .left {
    display: flex;
    flex-direction: column;
    .img {
      flex: none;
      width: 100%;
      height: 120px;
      margin-bottom: 15px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    > .text {
      width: 100%;
      flex: none;
      margin-bottom: 15px;

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .ref {
        display: block;
        font-size: 12px;
        margin-bottom: 3px;
        color: #4b4b4b;
      }

      .location {
        display: flex;
        font-size: 12px;
        align-items: center;

        svg {
          margin-right: 5px;
        }
      }
    }
  }

  .right {
    .info {
      display: flex;
      flex-direction: column;
      .block {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        &:first-child {
          .title {
            color: #787878;
          }
          .text {
            color: #787878;
          }
        }

        &:nth-child(3) {
          border-right: 1px solid #f4f4f4;
        }

        &:last-child {
          .title {
            color: #65b500;
          }
          .text {
            color: #787878;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 2px;
        }
        .text {
          display: block;
          font-size: 12px;
          order: -1;
          color: #4b4b4b;
        }
      }

      .user {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .prof {
          display: flex;
          width: 44px;
          height: 44px;
          margin-right: 20px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: #000;

          svg {
            width: 18px;
            height: 18px;
          }
        }

        .part {
          display: flex;
          width: 44px;
          height: 44px;
          font-size: 16px;
          margin-right: 20px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 2px solid var(--main-color);
          color: var(--main-color);
          background: #F2FAFD;

          svg {
            width: 18px;
            height: 18px;
          }
        }

        .content {
          .title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 2px;
          }

          .text {
            font-size: 12px;
          }
        }
      }
    }

    .status {
      display: flex;
      width: 100%;
      font-size: 12px;
      font-weight: 700;
      padding: 5px 10px;
      justify-content: center;
      text-transform: uppercase;
      border: 1px solid transparent;
      border-radius: 4px;

      &.green {
        border-color: #65b500;
        background: rgba(101, 181, 0, 0.3);
        color: #65b500;
      }

      &.red {
        border-color: #ab0000;
        background: rgba(171, 0, 0, 0.3);
        color: #ab0000;
      }

      &.blue {
        border-color: #0093d3;
        background: rgba(0, 147, 211, 0.3);
        color: #0093d3;
      }
    }
  }
}
