.root {
  .content {
    .price {
      margin-bottom: 15px;
      padding: 25px;
      border-radius: 10px;
      background: #fff;
      .title {
        font-size: 20px;
        margin-bottom: 25px;
      }
      .start {
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        margin-bottom: 15px;
        color: var(--main-color-text);
      }

      .last {
        display: flex;
        font-size: 18px;
        font-weight: 600;
        justify-content: space-between;
        margin-bottom: 15px;
      }
    }

    .counter {
      margin-bottom: 15px;
      padding: 25px;
      border-radius: 10px;
      background: #fff;

      .title {
        font-size: 20px;
        margin-bottom: 25px;
      }

      .wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        .block {
          display: flex;
          max-width: 230px;
          width: 100%;
          font-size: 24px;
          justify-content: center;
          align-items: center;
          padding: 8px;
          margin-right: 15px;
          border: 1px solid #f4f4f4;
          border-radius: 10px;

          .value {
            display: flex;
            align-items: center;
            padding: 0 20px;

            input {
              width: 100%;
              font-size: 24px;
              text-align: center;
              border: none;
            }
          }

          .btn {
            display: flex;
            flex: none;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 6px;
            background: var(--main-color);
            cursor: pointer;
            &:disabled {
              background: #99d4ed;
            }
          }
        }
      }

      .text {
        font-size: 10px;
        line-height: 16px;
      }

      .complete {
        font-size: 16px;
        text-align: center;
        margin: 10px 0;
        color: #65B500;
      }

      .deposit {
        display: flex;
        font-size: 20px;
        font-weight: 600;
        align-items: center;
        margin-top: 25px;
        padding: 12px 15px;
        background: #d1e9b3;
        border-radius: 10px;
        border: 1px solid #65b500;
        color: #65b500;

        svg {
          margin-right: 18px;
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  .content {
    .price {
      margin-bottom: 15px;
      padding: 5px;
      border-radius: 10px;
      background: #fff;
      .title {
        font-size: 18px;
        margin-bottom: 15px;
      }
      .start {
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        margin-bottom: 15px;
        color: var(--main-color-text);
      }

      .last {
        display: flex;
        font-size: 16px;
        font-weight: 600;
        justify-content: space-between;
      }
    }

    .counter {
      margin-bottom: 15px;
      padding: 5px;
      border-radius: 10px;
      background: #fff;

      .title {
        font-size: 18px;
        margin-bottom: 15px;
      }

      .wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        .block {
          display: flex;
          max-width: 230px;
          width: 100%;
          font-size: 24px;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          border: 1px solid #f4f4f4;
          border-radius: 10px;

          .value {
            display: flex;
            align-items: center;
            padding: 0 20px;

            input {
              width: 100%;
              font-size: 20px;
              text-align: center;
              border: none;
            }
          }

          .btn {
            display: flex;
            flex: none;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 6px;
            background: var(--main-color);
            cursor: pointer;
            &:disabled {
              background: #99d4ed;
            }
          }
        }
      }

      .text {
        font-size: 10px;
        line-height: 16px;
      }

      .complete {
        font-size: 14px;
        text-align: center;
        margin: 10px 0;
        color: #65B500;
      }

      .deposit {
        display: flex;
        font-size: 20px;
        font-weight: 600;
        align-items: center;
        margin-top: 25px;
        padding: 12px 15px;
        background: #d1e9b3;
        border-radius: 10px;
        border: 1px solid #65b500;
        color: #65b500;

        svg {
          margin-right: 18px;
        }
      }
    }
  }
}
