.root {
  font-size: 14px;

  .like {
    margin-bottom: 20px;
  }
  .content {
    position: relative;
    width: 100%;
    height: 225px;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

/* Mobile */

.rootMobile {
  font-size: 14px;
  margin-bottom: 20px;

  .like {
    margin-bottom: 20px;
  }
  .content {
    position: relative;
    width: 100%;
    height: 225px;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  }
}
