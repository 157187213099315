.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px 5px 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .img {
    width: 120px;
    height: 90px;
    margin-right: 20px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: var(--main-color-text);
  }

  > .text {
    max-width: 200px;
    width: 100%;
    margin: 0 auto 0 0;

    .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 2px;
    }

    .ref {
      font-size: 12px;
      color: #4b4b4b;
    }
  }

  .info {
    display: grid;
    grid-template-columns: repeat(3, 120px);
    grid-column-gap: 25px;
    margin-right: auto;

    .block {
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2px;
      }
      .text {
        display: block;
        font-size: 12px;
        color: #4b4b4b;
      }
    }
  }

  .timer {
    margin-right: 50px;
  }

  .status {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    margin: 0 65px 0 0;
    padding: 5px 10px;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 4px;

    &.green {
      border-color: #65b500;
      background: rgba(101, 181, 0, 0.3);
      color: #65b500;
    }

    &.yellow {
      border-color: #f2af03;
      background: rgba(242, 175, 3, 0.3);
      color: #f2af03;
    }


    &.blue {
      border-color: #0093D3;
      background: rgba(0, 147, 211, 0.3);
      color: #0093D3;
    }

    &.red {
      border-color: #AB0000;
      background: rgba(171, 0, 0, 0.3);
      color: #AB0000;
    }
  }

  .link {
    transform: rotate(-90deg);
    svg {
      path {
        fill: var(--main-color-title);
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .img {
    width: 100%;
    height: 150px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: var(--main-color-text);
  }

  > .text {
    width: 100%;
    margin-bottom: 15px;

    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 2px;
    }

    .ref {
      font-size: 12px;
      color: #4b4b4b;
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    .block {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 2px;
      }
      .text {
        display: block;
        font-size: 12px;
        order: -1;
        color: #4b4b4b;
      }
    }
  }

  .timer {
    margin-bottom: 30px;
  }

  .status {
    display: flex;
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    justify-content: center;
    margin-bottom: 15px;
    padding: 5px 10px;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 4px;

    &.green {
      border-color: #65b500;
      background: rgba(101, 181, 0, 0.3);
      color: #65b500;
    }

    &.yellow {
      border-color: #f2af03;
      background: rgba(242, 175, 3, 0.3);
      color: #f2af03;
    }


    &.blue {
      border-color: #0093D3;
      background: rgba(0, 147, 211, 0.3);
      color: #0093D3;
    }

    &.red {
      border-color: #AB0000;
      background: rgba(171, 0, 0, 0.3);
      color: #AB0000;
    }
  }

  .link {
    display: flex;
    justify-content: center;
    svg {
      transform: rotate(-90deg);
      path {
        fill: var(--main-color-title);
      }
    }
  }
}
