.root {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
  &:last-child {
    margin-bottom: 0;
  }
  .img {
    display: block;
    width: 182px;
    height: 136px;
    margin-right: 20px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content {
    .title {
      font-size: 16px;
      font-weight: 600;
    }

    .location {
      display: block;
      font-size: 12px;
      margin-bottom: 15px;
      color: var(--main-color-text);
    }

    .price {
      display: flex;
      font-size: 14px;
      align-items: center;
      margin-bottom: 25px;

      .text {
        margin-right: 5px;
        color: var(--main-color-text);
      }

      .value {
        font-weight: 600;
      }
    }

    .info {
      display: flex;

      .block {
        display: flex;
        font-size: 12px;
        align-items: center;
        margin-right: 15px;
        color: var(--main-color-text);
        &:last-child {
          margin-right: 0;
        }

        svg {
          margin-right: 8px;
        }
      }
    }
  }
}
