.root {
  display: flex;
  justify-content: space-between;

  .title {
    font-size: 36px;
    font-weight: 600;
  }

  .block {
    position: relative;
    display: flex;
    align-items: center;

    .select {
      width: 310px;
      flex: none;
    }

    .btn {
      display: flex;
      align-items: center;
      margin-left: 35px;
    }

    .search {
      margin-right: 10px;
      svg {
        path {
          fill: var(--main-color-title);
        }
      }
    }

    .filter {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      &.active {
        background: var(--main-color);

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .select {
      max-width: 200px;
      width: 100%;
      flex: none;
    }

    .btn {
      display: flex;
      align-items: center;
    }

    .search {
      line-height: 1;
      margin-right: 10px;
      svg {
        path {
          fill: var(--main-color-title);
        }
      }
    }

    .filter {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      &.active {
        background: var(--main-color);

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}
