.root {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 600px;
	z-index: 999;
	background: #fff;
	overflow-y: scroll;
	padding-bottom: 20px;

	.content {
		max-width: 1090px;
		width: 100%;
		margin-top: 55px;
		padding-bottom: 50px;

		> .title {
			font-size: 48px;
			font-weight: 800;
			text-align: center;
			padding: 55px 0;
		}

		.list {
			display: grid;
			grid-template-columns: repeat(10, 1fr);
			grid-column-gap: 20px;
			margin-bottom: 55px;

			.item {
				cursor: pointer;
				.icon {
					position: relative;
					display: flex;
					width: 100%;
					height: 92px;
					margin-bottom: 20px;
					justify-content: center;
					align-items: center;
					transition: all 0.3s ease-in-out;
					border: 2px solid #e9e9e9;
					border-radius: 10px;

					span {
						position: relative;
						z-index: 2;
					}

					&::after {
						content: '';
						position: absolute;
						top: 8px;
						left: 8px;
						width: 48px;
						height: 48px;
						border-radius: 50%;
						background: #99d4ed;
						z-index: 1;
					}
				}

				&:hover {
					.icon {
						border-color: var(--main-color);
					}
				}

				.title {
					font-size: 16px;
					font-weight: 400;
					text-align: center;
				}
			}
		}

		.wrap {
			display: flex;
			justify-content: space-between;

			.left {
				max-width: 420px;
				width: 100%;
				flex: none;

				.single {
					margin-bottom: 25px;
				}
			}

			.right {
				width: 100%;
				padding-left: 20px;

				.group {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-column-gap: 20px;
					margin-bottom: 25px;

					.date {
						display: flex;
						flex-direction: column;
						height: 100%;
						label {
							display: flex;
							padding: 10px 16px;
							border: 1px solid #d2d2d2;
							border-radius: 10px;

							&.error {
								border-color: red;
							}
							input {
								font-weight: 400;
								font-size: 16px;
								border: none;
								color: #d2d2d2;
								&::placeholder {
									color: #d2d2d2;
								}
							}
						}

						.title {
							font-weight: 400;
							font-size: 16px;
							margin-bottom: 8px;
							color: #1e1e1e;
						}
					}
				}

				.checkbox {
					.title {
						font-size: 16px;
						font-weight: 400;
						margin-bottom: 20px;
					}

					.list {
						.item {
							cursor: pointer;
						}
					}
				}
			}
		}

		.range {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 20px;
			margin-bottom: 55px;

			.block {
				& .MuiSlider-rail {
					background: #f4f4f4;
				}
				.top {
					display: flex;
					font-size: 16px;
					justify-content: space-between;
					margin-bottom: 10px;

					.title {
						font-weight: 400;
					}
				}

				.bottom {
					display: flex;
					justify-content: space-between;
					color: #d2d2d2;

					.block {
						display: flex;
						flex-direction: column;

						.price {
							font-size: 12px;
						}

						.text {
							font-size: 10px;
						}
					}
				}
			}
		}

		.bottom {
			display: flex;
			justify-content: center;

			.btn {
				width: 140px;
			}
		}
	}

	.result {
		width: 100%;
		padding-bottom: 55px;
		background: #fbfbfb;

		.line {
			font-size: 14px;
			font-weight: 700;
			text-align: center;
			padding: 12px 0;
			margin-bottom: 55px;
			background: #e6f4fb;
			color: var(--main-color);
		}
	}
	.close {
		position: absolute;
		top: 75px;
		right: 75px;
		border: none;
		background: none;
		cursor: pointer;
	}
}

/* Mobile */

.rootMobile {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	flex-direction: column;
	padding: 50px 15px 0 15px;
	background: #fff;
	overflow-x: scroll;
	z-index: 999;

	.content {
		max-width: 480px;
		width: 100%;

		> .title {
			font-size: 25px;
			font-weight: 800;
			text-align: center;
			margin-bottom: 15px;
		}

		.list {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 20px;
			margin-bottom: 30px;

			.item {
				cursor: pointer;
				.icon {
					position: relative;
					display: flex;
					width: 100%;
					height: 50px;
					margin-bottom: 10px;
					justify-content: center;
					align-items: center;
					transition: all 0.3s ease-in-out;
					border: 2px solid #e9e9e9;
					border-radius: 10px;

					span {
						position: relative;
						z-index: 2;

						svg {
							width: 30px;
							height: 30px;
						}
					}

					&::after {
						content: '';
						position: absolute;
						top: 8px;
						left: 30px;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						background: #99d4ed;
						z-index: 1;
					}
				}

				&:hover {
					.icon {
						border-color: var(--main-color);
					}
				}

				.title {
					font-size: 14px;
					font-weight: 400;
					text-align: center;
				}
			}
		}

		.wrap {
			display: flex;
			flex-direction: column;

			.left {
				width: 100%;
				flex: none;

				.single {
					margin-bottom: 15px;
				}
			}

			.right {
				width: 100%;

				.group {
					flex-direction: column;
					margin-bottom: 15px;

					.input {
						margin-bottom: 15px;

						.date {
							display: flex;
							flex-direction: column;
							height: 100%;
							label {
								display: flex;
								padding: 10px 16px;
								border: 1px solid #d2d2d2;
								border-radius: 10px;

								&.error {
									border-color: red;
								}
								input {
									font-weight: 400;
									font-size: 16px;
									border: none;
									color: #d2d2d2;
									&::placeholder {
										color: #d2d2d2;
									}
								}
							}

							.title {
								font-weight: 400;
								font-size: 14px;
								margin-bottom: 10px;
								color: #1e1e1e;
							}
						}
					}
				}

				.checkbox {
					.title {
						font-size: 14px;
						font-weight: 400;
						margin-bottom: 20px;
					}

					.list {
						.item {
							cursor: pointer;
						}
					}
				}
			}
		}

		.range {
			.block {
				margin-bottom: 15px;

				& .MuiSlider-rail {
					background: #f4f4f4;
				}
				.top {
					display: flex;
					font-size: 14px;
					justify-content: space-between;
					margin-bottom: 10px;

					.title {
						font-weight: 400;
					}
				}

				.bottom {
					display: flex;
					justify-content: space-between;
					color: #d2d2d2;

					.block {
						display: flex;
						flex-direction: column;

						.price {
							font-size: 12px;
						}

						.text {
							font-size: 10px;
						}
					}
				}
			}
		}

		> .bottom {
			display: flex;
			justify-content: center;
			padding-bottom: 30px;

			.btn {
				width: 140px;
			}
		}
	}

	.close {
		position: absolute;
		top: 15px;
		right: 15px;
		border: none;
		background: none;
		cursor: pointer;

		svg {
			width: 25px;
			height: 25px;
		}
	}
}

.provinces {
	display: flex;
	align-items: center;
	margin-top: 16px;
	flex-wrap: wrap;

	.provinceItem {
		margin-right: 8px;
		background: #0093d3;
		border-radius: 6px;
		padding: 4px 8px;
		color: #ffffff;
		border: 1px solid #ffffff;
		display: flex;
		align-items: center;

		.deleteProvince {
			margin-left: 10px;
			cursor: pointer;
		}
	}
}
