.wrapper {
  display: flex;
  flex-direction: column;

  .label {
    font-weight: 400;
    font-size: 16px;
    color: #1e1e1e;
  }

  .input {
    font-weight: 400;
    font-size: 16px;
    color: #1e1e1e;
    margin-top: 8px;
    padding: 12px 16px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    width: 100%;
    &.error {
      border-color: red;
    }
    &::placeholder {
      color: #d2d2d2;
      font-weight: 400;
      font-size: 16px;
    }
  }
}

/* Mobile */

.wrapperMobile {
  display: flex;
  flex-direction: column;

  .label {
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
  }

  .input {
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-top: 8px;
    padding: 12px 16px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    width: 100%;
    &.error {
      border-color: red;
    }

    &::placeholder {
      color: #d2d2d2;
      font-weight: 400;
      font-size: 12px;
    }
  }
}
