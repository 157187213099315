.root {
  position: absolute;
  top: 120%;
  right: 0;
  width: 420px;
  padding: 30px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
  z-index: 99;

  > .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .single {
    width: 100%;
    margin-bottom: 25px;
  }

  .group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    margin-bottom: 40px;
  }

  .date {
    margin-bottom: 40px;

    .title {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 8px;
    }

    .block {
      display: flex;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      color: #1e1e1e;
      margin-top: 8px;
      padding: 12px 16px;
      background: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 10px;

      input {
        font-size: 16px;
        border: none;

        &::placeholder {
          color: #d2d2d2;
        }
      }
    }
  }

  .bottom {
    .link {
      display: block;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      text-decoration: underline;
      margin-top: 20px;
      color: var(--main-color);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  position: absolute;
  top: 120%;
  right: 0;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
  z-index: 99;

  > .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .single {
    width: 100%;
    margin-bottom: 15px;
  }

  .group {
    display: flex;
    flex-direction: column;

    .input {
      margin-bottom: 15px;
    }
  }

  .date {
    margin-bottom: 40px;

    .title {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 8px;
    }

    .block {
      display: flex;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      color: #1e1e1e;
      margin-top: 8px;
      padding: 12px 16px;
      background: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 10px;

      input {
        font-size: 16px;
        border: none;

        &::placeholder {
          color: #d2d2d2;
        }
      }
    }
  }

  .bottom {
    .link {
      display: block;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      text-decoration: underline;
      margin-top: 20px;
      color: var(--main-color);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
