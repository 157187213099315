.root {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  background: #fff;

  > .img {
    max-width: 160px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    padding-left: 20px;

    .title {
      font-size: 16px;
      font-weight: 600;
    }

    .location {
      font-size: 12px;
      margin-bottom: 15px;
      color: #787878;
    }

    .price {
      font-size: 14px;
      margin-bottom: 20px;

      span {
        font-weight: 600;
      }
    }

    .user {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .icon {
        flex: none;
        display: flex;
        width: 24px;
        height: 25px;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #000;

        svg {
          width: 10px;
          height: 10px;
        }
      }

      .img {
        flex: none;
        display: flex;
        width: 24px;
        height: 25px;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .content {
        font-size: 12px;

        span {
          margin-right: 5px;
        }
      }
    }

    .link {
      display: flex;
      font-size: 16px;
      font-weight: 700;
      align-items: center;
      color: var(--main-color);

      svg {
        margin-left: 10px;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  > .img {
    width: 100%;
    height: 160px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .location {
      font-size: 12px;
      margin-bottom: 15px;
      color: #787878;
    }

    .price {
      font-size: 14px;
      margin-bottom: 10px;

      span {
        font-weight: 600;
      }
    }

    .user {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .icon {
        flex: none;
        display: flex;
        width: 24px;
        height: 25px;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #000;

        svg {
          width: 10px;
          height: 10px;
        }
      }

      .img {
        flex: none;
        display: flex;
        width: 24px;
        height: 25px;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .content {
        font-size: 12px;

        span {
          margin-right: 5px;
        }
      }
    }

    .link {
      display: flex;
      font-size: 16px;
      font-weight: 700;
      align-items: center;
      color: var(--main-color);

      svg {
        margin-left: 10px;
      }
    }
  }
}
