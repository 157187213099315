.root {
  .top {
    display: flex;
    justify-content: space-between;

    .left {
      .title {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 4px;
        color: var(--main-color-text);
      }

      .value {
        font-size: 18px;
        font-weight: 600;
        color: var(--main-color-title);
      }
    }

    .right {
      display: flex;
      align-items: flex-end;

      span {
        font-size: 12px;
        padding: 4px 15px;
        border-radius: 16px;
        color: #fff;

        &.individ {
          margin-right: 8px;
          background: var(--main-color);
        }

        &.prof {
          background: #f2af03;
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  .top {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .left {
      margin-bottom: 15px;

      .title {
        font-size: 10px;
        font-weight: 400;
        margin-bottom: 4px;
        color: var(--main-color-text);
      }

      .value {
        font-size: 16px;
        font-weight: 600;
        color: var(--main-color-title);
      }
    }

    .right {
      display: flex;
      align-items: flex-end;

      span {
        font-size: 12px;
        padding: 4px 15px;
        border-radius: 16px;
        color: #fff;

        &.individ {
          margin-right: 8px;
          background: var(--main-color);
        }

        &.prof {
          background: #f2af03;
        }
      }
    }
  }
}
