.root {
  padding: 25px;
  border-radius: 10px;
  background: #fff;

  > .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .top {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .name {
      display: flex;
      width: 60px;
      height: 60px;
      font-size: 20px;
      margin-right: 25px;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--main-color);
      border-radius: 50%;
      background: #f2fafd;
      color: var(--main-color);
    }

    .icon {
      display: flex;
      width: 60px;
      height: 60px;
      font-size: 20px;
      margin-right: 25px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #000;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .content {
      .title {
        font-size: 18px;
        font-weight: 400;
      }

      .role {
        font-size: 14px;
        color: #4b4b4b;
      }
    }
  }

  .block {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #f4f4f4;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }

    .icon {
      display: flex;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 15px;

        path {
          fill: var(--main-color-title);
        }
      }
    }

    .text {
      position: relative;

      span {
        margin-right: 5px;
      }

      button {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 140px;
        width: 100%;
        font-size: 14px;
        padding: 10px;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  padding: 15px 5px;
  border-radius: 10px;
  background: #fff;

  > .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .top {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .name {
      display: flex;
      width: 50px;
      height: 50px;
      font-size: 18px;
      margin-right: 15px;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--main-color);
      border-radius: 50%;
      background: #f2fafd;
      color: var(--main-color);
    }

    .icon {
      display: flex;
      width: 50px;
      height: 50px;
      font-size: 18px;
      margin-right: 15px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #000;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .content {
      .title {
        font-size: 16px;
        font-weight: 400;
      }

      .role {
        font-size: 12px;
        color: #4b4b4b;
      }
    }
  }

  .block {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #f4f4f4;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }

    .icon {
      display: flex;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 15px;

        path {
          fill: var(--main-color-title);
        }
      }
    }

    .text {
      position: relative;
      font-size: 14px;

      span {
        margin-right: 5px;
      }

      button {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 140px;
        width: 100%;
        font-size: 12px;
        padding: 10px;
      }
    }
  }
}
