.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 75px;
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;

  .block {
    &:nth-child(1) {
      margin-bottom: 30px;
    }
  }
}
