.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 45px 5px 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  .left {
    display: flex;
    width: 48%;
    align-items: center;

    .img {
      flex: none;
      display: block;
      max-width: 84px;
      width: 100%;
      height: 72px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .content {
      padding-left: 25px;

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .location {
        display: flex;
        font-size: 12px;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .right {
    display: flex;

    .price {
      margin-right: 30px;

      .value {
        font-size: 18px;
        font-weight: 600;

        &.gray {
          color: #787878;
        }

        &.green {
          color: #65b500;
        }
      }

      .title {
        font-size: 12px;
        font-weight: 400;

        &.gray {
          color: #787878;
        }

        &.green {
          color: var(--main-color-title);
        }
      }
    }

    .link {
      position: relative;
      display: flex;
      width: 30px;

      svg {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) rotate(-90deg);

        path {
          fill: var(--main-color-title);
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  .left {
    display: flex;
    width: 100%;
    flex-direction: column;

    .img {
      flex: none;
      display: block;
      width: 100%;
      height: 160px;
      margin-bottom: 15px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .content {
      margin-bottom: 15px;

      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .location {
        display: flex;
        font-size: 10px;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .right {
    display: flex;
    width: 100%;
    flex-direction: column;

    .price {
      margin-bottom: 10px;

      .value {
        font-size: 16px;
        font-weight: 600;

        &.gray {
          color: #787878;
        }

        &.green {
          color: #65b500;
        }
      }

      .title {
        font-size: 10px;
        font-weight: 400;

        &.gray {
          color: #787878;
        }

        &.green {
          color: var(--main-color-title);
        }
      }
    }

    .link {
      position: relative;
      display: flex;
      width: 30px;
      height: 30px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);

        path {
          fill: var(--main-color-title);
        }
      }
    }
  }
}
