.root {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 999;

  .content {
    max-width: 500px;
    width: 100%;
    background: #fff;

    > .title {
      font-size: 35px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 55px;
    }

    .btn {
      display: flex;
      justify-content: center;

      button {
        width: 120px;

        &:first-child {
          margin-right: 30px;
        }
      }
    }
  }

  .close {
    position: absolute;
    top: 75px;
    right: 75px;
    border: none;
    background: none;
    cursor: pointer;
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 999;

  .content {
    max-width: 500px;
    width: 100%;
    background: #fff;

    > .title {
      font-size: 35px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 55px;
    }

    .btn {
      display: flex;
      justify-content: center;

      button {
        width: 120px;

        &:first-child {
          margin-right: 30px;
        }
      }
    }
  }

  .close {
    position: absolute;
    top: 75px;
    right: 75px;
    border: none;
    background: none;
    cursor: pointer;
  }
}
