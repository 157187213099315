.root {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  background: #e9e9e9;
  z-index: 999;

  .content {
    position: relative;
    max-width: 640px;
    width: 100%;
    padding: 30px;
    border-radius: 10px;
    background: #fff;

    .title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 25px;
    }

    .textarea {
      width: 100%;
      height: 236px;
      font-size: 12px;
      padding: 25px 30px;
      margin-bottom: 55px;
      border-radius: 10px;
      background: #f4f4f4;
      outline: none;
      color: var(--main-color-title);

      &::placeholder {
        color: var(--main-color-title);
      }
    }

    .btn {
      display: block;
      max-width: 210px;
      width: 100%;
      margin: 0 auto;
    }

    .close {
      position: absolute;
      top: 25px;
      right: 25px;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}
