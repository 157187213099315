.root {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 999;

  .wrap {
    max-width: 860px;
    width: 100%;
  }

  .close {
    position: absolute;
    top: 75px;
    right: 75px;
    cursor: pointer;
    border: none;
    background: none;
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 50px 0;
  justify-content: center;
  align-items: center;
  background: #fff;
  overflow-x: scroll;
  z-index: 999;

  .wrap {
    width: 100%;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    border: none;
    background: none;

    svg {
      width: 25px;
      height: 25px;
    }
  }
}
