@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900;1000&display=swap');
@import './assets/styles/variables/variables.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: 'Mulish', sans-serif;
  height: 100%;
  overflow-x: hidden;
  color: var(--main-color-title);
}

button,
input {
  font-family: 'Mulish', sans-serif;
  outline: none;
}

input {
  &::placeholder {
    font-family: 'Mulish', sans-serif;
  }
}
a {
  text-decoration: none;
}
#root {
  height: 100%;
}

.container {
  max-width: 1330px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
