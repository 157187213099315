.root {
	.title {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 25px;
	}
}

/* Mobile */

.rootMobile {
	.title {
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 20px;
	}
}
