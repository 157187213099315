.root {


  .modal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .title {
      font-size: 36px;
      font-weight: 600;
    }

    .btn {
      max-width: 205px;
      width: 100%;
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}

/* Mobile */

.rootMobile {


  .modal {
    position: absolute;
    top: 0;
    left: 0;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .title {
      font-size: 25px;
      font-weight: 600;
    }

    .btn {
      width: 150px;

      button {
        font-size: 12px;
        padding: 12px 20px;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
  }
}
