.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  .inputGrey {
    font-weight: 400;
    font-size: 16px;
    background: #f4f4f4;
    border-radius: 10px;
    padding: 16px;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      color: #787878;
    }
  }

  .inputWhite {
    font-weight: 400;
    font-size: 16px;
    color: #1e1e1e;
    padding: 12px 16px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    width: 100%;

    &::placeholder {
      color: #d2d2d2;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .inputLeftIcon {
    padding-left: 50px;
  }

  .iconLeft {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    left: 16px;
  }
}

.wrapperMobile {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  .inputGrey {
    font-weight: 400;
    font-size: 14px;
    background: #f4f4f4;
    border-radius: 10px;
    padding: 16px;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #787878;
    }
  }

  .inputWhite {
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding: 10px 14px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    width: 100%;

    &::placeholder {
      color: #d2d2d2;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .inputLeftIcon {
    padding-left: 50px;
  }

  .iconLeft {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    left: 16px;
  }
}
