.root {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px 15px 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .left {
    display: flex;
    max-width: 350px;
    width: 100%;
    align-items: center;

    .icon {
      display: flex;
      width: 72px;
      height: 72px;
      font-size: 24px;
      margin-right: 25px;
      align-items: center;
      justify-content: center;
      border: 2px solid #66bee5;
      border-radius: 50%;
      color: var(--main-color);
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--main-color-title);
    }
  }

  .center {
    display: grid;
    max-width: 500px;
    width: 100%;
    grid-template-columns: repeat(3, 150px);
    grid-column-gap: 5px;

    .block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    .value {
      display: block;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .title {
      font-size: 12px;
      font-weight: 400;
      color: #4b4b4b;
    }
  }

  .right {
    position: relative;
    display: flex;
    max-width: 200px;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .select {
      position: absolute;
      top: 0;
      right: 0;
      width: 200px;
      span {
        font-size: 14px !important;
      }
    }

    .role {
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      border-left: 2px solid #f4f4f4;
      .value {
        display: block;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      .title {
        font-size: 12px;
        font-weight: 400;
        color: #4b4b4b;
      }
    }

    .edit {
      margin-left: 20px;
      cursor: pointer;
      button {
        border: none;
        background: none;
        cursor: pointer;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .left {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;

    .icon {
      display: flex;
      width: 60px;
      height: 60px;
      font-size: 18px;
      margin-right: 25px;
      align-items: center;
      justify-content: center;
      border: 2px solid #66bee5;
      border-radius: 50%;
      color: var(--main-color);
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      color: var(--main-color-title);
    }
  }

  .center {
    display: flex;
    flex-direction: column;

    .block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-bottom: 15px;
    }
    .value {
      display: block;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .title {
      font-size: 10px;
      font-weight: 400;
      color: #4b4b4b;
    }
  }

  .right {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .role {
      display: flex;
      flex-direction: column;
      border-left: 2px solid #f4f4f4;
      .value {
        display: block;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      .title {
        font-size: 12px;
        font-weight: 400;
        color: #4b4b4b;
      }
    }

    .edit {
      button {
        border: none;
        background: none;

        &:first-child {
          margin-right: 25px;
        }
      }
    }
  }
}
