.root {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 999;

  .content {
    max-width: 860px;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-weight: 800;
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      color: #1e1e1e;
    }

    .legal {
      margin-top: 55px;
      background: #f4f4f4;
      border-radius: 10px;
      padding: 32px;
      max-height: 50vh;
      overflow: auto;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    .check {
      margin-top: 24px;
      width: 100%;
    }

    .btn {
      display: flex;
      justify-content: center;
      margin-top: 55px;
    }
  }

  .close {
    position: absolute;
    top: 75px;
    right: 75px;
    border: none;
    background: none;
    cursor: pointer;
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 999;

  .content {
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    align-items: center;

    .title {
      font-weight: 800;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      color: #1e1e1e;
    }

    .legal {
      margin-top: 30px;
      background: #f4f4f4;
      border-radius: 10px;
      padding: 15px;
      max-height: 50vh;
      overflow: auto;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    .check {
      margin-top: 24px;
      width: 100%;
      flex: none;
    }
    .btn {
      display: flex;
      justify-content: center;
      margin-top: 55px;
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: none;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
