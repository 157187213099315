.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 45px 15px 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  .left {
    display: flex;
    max-width: 320px;
    width: 100%;
    flex: none;
    align-items: center;

    .icon {
      display: flex;
      width: 72px;
      height: 72px;
      align-items: center;
      justify-content: center;
      margin-right: 25px;
      border-radius: 50%;
      border: 1px solid transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .img {
        width: 100%;
      }
      svg {
        &:last-child {
          display: none;
        }
      }
    }

    .name {
      flex: none;
      display: flex;
      width: 72px;
      height: 72px;
      font-size: 24px;
      align-items: center;
      justify-content: center;
      margin-right: 25px;
      border-radius: 50%;
      border: 1px solid var(--main-color);
      color: var(--main-color);
    }

    .text {
      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 4px;
      }

      .cif {
        font-size: 12px;
        color: #a5a5a5;
      }
    }
  }

  .center {
    .list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 25px;

      .item {
        .value {
          display: block;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 4px;
        }
        .title {
          font-size: 12px;
          font-weight: 400;
          color: #a5a5a5;
        }
      }
    }
  }

  .right {
    display: flex;
    padding-left: 35px;
    border-left: 2px solid #f4f4f4;

    .date {
      margin-right: 90px;

      .value {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 4px;
      }
      .title {
        font-size: 12px;
        color: #a5a5a5;
      }
    }

    .btn {
      display: flex;
      align-items: center;

      .status {
        margin-right: 25px;

        &.red {
          svg {
            path {
              fill: red;
            }
          }
        }

        &.green {
          svg {
            path {
              fill: #65b500;
            }
          }
        }
      }

      .remove {
        margin-right: 55px;
      }

      .link {
        transform: rotate(-90deg);

        svg {
          path {
            fill: var(--main-color-title);
          }
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  .left {
    display: flex;
    width: 100%;
    flex: none;
    align-items: center;
    margin-bottom: 15px;

    .icon {
      display: flex;
      width: 72px;
      height: 72px;
      align-items: center;
      justify-content: center;
      margin-right: 25px;
      border-radius: 50%;
      border: 1px solid transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .img {
        width: 100%;
      }
      svg {
        &:last-child {
          display: none;
        }
      }
    }

    .name {
      flex: none;
      display: flex;
      width: 72px;
      height: 72px;
      font-size: 24px;
      align-items: center;
      justify-content: center;
      margin-right: 25px;
      border-radius: 50%;
      border: 1px solid var(--main-color);
      color: var(--main-color);
    }

    .text {
      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 4px;
      }

      .cif {
        font-size: 12px;
        color: #a5a5a5;
      }
    }
  }

  .center {
    margin-bottom: 15px;

    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;

      .item {
        .value {
          display: block;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 4px;
        }
        .title {
          font-size: 12px;
          font-weight: 400;
          color: #a5a5a5;
        }
      }
    }
  }

  .right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-left: 2px solid #f4f4f4;

    .date {
      .value {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 4px;
      }
      .title {
        font-size: 12px;
        color: #a5a5a5;
      }
    }

    .btn {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 15px;

      .status {
        display: flex;
        align-items: center;
        justify-content: center;

        &.red {
          svg {
            path {
              fill: red;
            }
          }
        }

        &.green {
          svg {
            path {
              fill: #65b500;
            }
          }
        }
      }

      .remove {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .link {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(-90deg);

        svg {
          path {
            fill: var(--main-color-title);
          }
        }
      }
    }
  }
}
