.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px 5px 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .wrap {
    display: flex;
    align-items: center;

    &:last-child {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .img {
      width: 120px;
      height: 90px;
      margin-right: 20px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .user {
      display: flex;
      flex: none;
      width: 250px;
      align-items: center;
      margin-right: 30px;

      .prof {
        display: flex;
        width: 44px;
        height: 44px;
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #000;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .part {
        display: flex;
        width: 44px;
        height: 44px;
        font-size: 16px;
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid var(--main-color);
        color: var(--main-color);
        background: #F2FAFD;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .content {
        .title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 2px;
        }

        .text {
          font-size: 12px;
        }
      }
    }
    > .text {
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2px;
      }

      .ref {
        font-size: 12px;
        color: #4b4b4b;
      }
    }

    .info {
      display: grid;
      grid-template-columns: repeat(3, 110px);
      grid-column-gap: 25px;
      .block {
        .title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 2px;
        }
        .text {
          display: block;
          font-size: 12px;
          color: #4b4b4b;
        }

        &:last-child {
          text-align: right;
          margin-right: 0;
          border-left: 1px solid #f4f4f4;
        }
      }
    }

    .status {
      display: flex;
      width: 90px;
      font-size: 12px;
      font-weight: 700;
      justify-content: center;
      margin: 0 60px 0 auto;
      padding: 5px 10px;
      text-transform: uppercase;
      border: 1px solid transparent;
      border-radius: 4px;

      &.green {
        border-color: #65b500;
        background: rgba(101, 181, 0, 0.3);
        color: #65b500;
      }

      &.yellow {
        border-color: #f2af03;
        background: rgba(242, 175, 3, 0.3);
        color: #f2af03;
      }
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .img {
      width: 100%;
      height: 150px;
      border-radius: 10px;
      margin-bottom: 15px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .user {
      display: flex;
      width: 100%;
      flex: none;
      margin-bottom: 15px;

      .prof {
        display: flex;
        width: 44px;
        height: 44px;
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #000;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .part {
        display: flex;
        width: 44px;
        height: 44px;
        font-size: 16px;
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid var(--main-color);
        color: var(--main-color);
        background: #F2FAFD;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .content {
        .title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 2px;
        }

        .text {
          font-size: 12px;
        }
      }
    }
    > .text {
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2px;
      }

      .ref {
        font-size: 12px;
        color: #4b4b4b;
      }
    }

    .info {
      display: flex;
      flex-direction: column;

      .block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 5px;
        }
        .text {
          display: block;
          font-size: 12px;
          order: -1;
          color: #4b4b4b;
        }
      }
    }

    .status {
      display: flex;
      width: 100%;
      font-size: 12px;
      font-weight: 700;
      padding: 5px 10px;
      margin-bottom: 15px;
      text-transform: uppercase;
      justify-content: center;
      border: 1px solid transparent;
      border-radius: 4px;

      &.green {
        border-color: #65b500;
        background: rgba(101, 181, 0, 0.3);
        color: #65b500;
      }

      &.yellow {
        border-color: #f2af03;
        background: rgba(242, 175, 3, 0.3);
        color: #f2af03;
      }
    }
  }
}
