.root {
  .title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 45px;
  }
  .list {
    margin-bottom: 55px;
  }
}

/* Mobile */

.rootMobile {
  .title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .list {
    margin-bottom: 55px;
  }
}
