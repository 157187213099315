.root {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  justify-content: space-between;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .left {
    display: flex;
    align-items: center;
    .icon {
      display: flex;
      width: 72px;
      height: 72px;
      margin-right: 25px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #000;
    }

    .name {
      display: flex;
      width: 72px;
      height: 72px;
      font-size: 24px;
      margin-right: 25px;
      align-items: center;
      justify-content: center;
      border: 2px solid #66bee5;
      border-radius: 50%;
      color: var(--main-color);
    }

    .content {
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2px;
      }

      .text {
        font-size: 12px;
        color: #a5a5a5;
      }
    }
  }

  .right {
    .date {
      display: flex;
      font-weight: 600;
      margin-bottom: 2px;
      color: var(--main-color-title);
    }

    .text {
      display: block;
      font-size: 12px;
      text-align: right;
      color: #4b4b4b;
    }
  }
}

/* Mobile */

.rootMobile {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 10px;
  justify-content: space-between;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .left {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .icon {
      display: flex;
      width: 50px;
      height: 50px;
      margin-right: 15px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #000;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .name {
      display: flex;
      width: 50px;
      height: 50px;
      font-size: 20px;
      margin-right: 15px;
      align-items: center;
      justify-content: center;
      border: 2px solid #66bee5;
      border-radius: 50%;
      color: var(--main-color);
    }

    .content {
      .title {
        font-size: 16px;
        font-weight: 600;
      }

      .text {
        font-size: 10px;
        color: #a5a5a5;
      }
    }
  }

  .right {
    .date {
      display: flex;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 2px;
      color: var(--main-color-title);
    }

    .text {
      display: block;
      font-size: 12px;
      color: #4b4b4b;
    }
  }
}
