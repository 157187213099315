.root {
  position: relative;
  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;

    a {
      display: inline-flex;
      font-size: 16px;
      align-items: center;
      margin-left: 10px;
      color: var(--main-color);

      svg {
        margin-left: 10px;
      }
    }
  }
  .next {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    right: -40px;
    top: 50%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    transform: translateY(-50%);
    background: var(--main-color-text);
    opacity: 0.1;
    cursor: pointer;

    &.disabled {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 18px;
      top: 11px;
      width: 3px;
      height: 12px;
      background: #fff;
      transform: rotate(-45deg);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 18px;
      bottom: 11px;
      width: 3px;
      height: 12px;
      background: #fff;
      transform: rotate(45deg);
    }
  }

  .prev {
    display: block;
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    left: -40px;
    top: 50%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    transform: translateY(-50%);
    background: var(--main-color-text);
    opacity: 0.1;
    z-index: 999;
    cursor: pointer;
    &.disabled {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 18px;
      top: 11px;
      width: 3px;
      height: 12px;
      background: #fff;
      transform: rotate(230deg);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 18px;
      bottom: 11px;
      width: 3px;
      height: 12px;
      background: #fff;
      transform: rotate(-230deg);
    }
  }
}

/* Mobile */

.rootMobile {
  position: relative;
  .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;

    a {
      display: inline-flex;
      font-size: 16px;
      align-items: center;
      margin-left: 10px;
      color: var(--main-color);

      svg {
        margin-left: 10px;
      }
    }
  }
  .next {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    right: 0;
    top: 120px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: var(--main-color-text);
    opacity: 0.1;
    cursor: pointer;
    z-index: 2;

    &.disabled {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 18px;
      top: 11px;
      width: 3px;
      height: 12px;
      background: #fff;
      transform: rotate(-45deg);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 18px;
      bottom: 11px;
      width: 3px;
      height: 12px;
      background: #fff;
      transform: rotate(45deg);
    }
  }

  .prev {
    display: block;
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    left: 0;
    top: 120px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: var(--main-color-text);
    opacity: 0.1;
    z-index: 999;
    cursor: pointer;
    &.disabled {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 18px;
      top: 11px;
      width: 3px;
      height: 12px;
      background: #fff;
      transform: rotate(230deg);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 18px;
      bottom: 11px;
      width: 3px;
      height: 12px;
      background: #fff;
      transform: rotate(-230deg);
    }
  }
}
