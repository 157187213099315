.root {
  position: relative;
  display: grid;
  min-height: 100%;
  grid-template-rows: auto 1fr auto;

  .main {
    overflow-x: hidden;
    padding: 30px 0;
    background: #fbfbfb;
  }
}
