.root {
  padding: 25px;
  border-radius: 10px;
  background: #fff;

  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .price {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-size: 16px;
      font-weight: 600;
    }

    .value {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

/* Mobile */

.rootMobile {
  padding: 15px 5px;
  border-radius: 10px;
  background: #fff;

  .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .price {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-size: 14px;
      font-weight: 600;
    }

    .value {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
