.wrapper {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  background: #e9e9e9;
  opacity: 0;
  visibility: hidden;
  z-index: 999;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .content {
    position: relative;
    display: flex;
    max-width: 640px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 56px;
    border-radius: 10px;
    background: #fff;

    .title {
      font-size: 20px;
    }

    .img {
      margin: 40px 0 56px 0;
    }

    .btn {
      max-width: 192px;
      width: 100%;
    }

    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;

      &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 2px;
        height: 14px;
        transform: translate(-50%, -50%) rotate(-45deg);
        background: #d2d2d2;
      }

      &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 2px;
        height: 14px;
        transform: translate(-50%, -50%) rotate(45deg);
        background: #d2d2d2;
      }
    }
  }
}

.wrapperMobile {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  background: #e9e9e9;
  opacity: 0;
  visibility: hidden;
  z-index: 999;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .content {
    position: relative;
    display: flex;
    max-width: 320px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 56px;
    border-radius: 10px;
    background: #fff;

    .title {
      font-size: 16px;
    }

    .img {
      margin: 20px 0 30px;
    }

    .btn {
      max-width: 192px;
      width: 100%;
    }

    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;

      &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 2px;
        height: 14px;
        transform: translate(-50%, -50%) rotate(-45deg);
        background: #d2d2d2;
      }

      &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 2px;
        height: 14px;
        transform: translate(-50%, -50%) rotate(45deg);
        background: #d2d2d2;
      }
    }
  }
}
