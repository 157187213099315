.root {
	padding: 25px;
	border-radius: 10px;
	background: #fff;

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 40px;

		.inner {
			font-size: 20px;
			font-weight: 600;
		}

		.counter {
			font-size: 16px;
			font-weight: 700;
			color: var(--main-color);
		}
	}

	.list {
		display: grid;
		grid-row-gap: 25px;

		.item {
			display: flex;
			justify-content: space-between;
			padding-bottom: 25px;
			border-bottom: 1px solid #f4f4f4;

			.block {
				.name {
					display: block;
					font-size: 16px;
					margin-bottom: 5px;
				}

				.date {
					display: block;
					font-size: 12px;
					color: #787878;
				}
			}

			.price {
				font-size: 18px;
				font-weight: 600;
			}
		}
	}
}

/* Mobile */

.rootMobile {
	padding: 15px 5px;
	border-radius: 10px;
	background: #fff;

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 25px;

		.inner {
			font-size: 18px;
			font-weight: 600;
		}

		.counter {
			font-size: 16px;
			font-weight: 700;
			color: var(--main-color);
		}
	}

	.list {
		display: grid;
		grid-row-gap: 15px;

		.item {
			display: flex;
			justify-content: space-between;
			padding-bottom: 15px;
			border-bottom: 1px solid #f4f4f4;

			.block {
				.name {
					display: block;
					font-size: 16px;
					margin-bottom: 5px;
				}

				.date {
					display: block;
					font-size: 11px;
					color: #787878;
				}
			}

			.price {
				font-size: 16px;
				font-weight: 600;
			}
		}
	}
}
