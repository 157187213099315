.root {
  > .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  > .list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    margin-bottom: 25px;

    .item {
      display: flex;
      flex-direction: column;
      padding: 25px 30px;
      height: 170px;
      border-radius: 10px;

      .icon {
        display: block;
        svg {
          width: 32px;
          height: 32px;
        }
      }
      &:nth-child(1) {
        background: var(--main-color);
      }
      &:nth-child(2) {
        background: var(--main-color);
      }
      &:nth-child(3) {
        background: #fff;
        .count {
          color: var(--main-color-title);
        }

        .title {
          color: var(--main-color-title);
        }
        svg {
          path {
            fill: var(--main-color);
          }
        }
      }
      &:nth-child(4) {
        background: #fff;

        .count {
          color: var(--main-color-title);
        }

        .title {
          color: var(--main-color-title);
        }
        svg {
          path {
            fill: var(--main-color);
          }
        }
      }

      .count {
        font-size: 36px;
        font-weight: 600;
        margin: 15px 0 10px 0;
        color: #fff;
      }

      .title {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
      }
    }
  }

  .chart {
    padding: 40px 30px;
    border-radius: 10px;
    background: #fff;

    .switch {
      margin-bottom: 30px;

      .list {
        position: relative;
        display: flex;

        &::after {
          content: '';
          position: absolute;
          display: block;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background: #f4f4f4;
        }

        .item {
          font-size: 20px;
          padding-bottom: 12px;
          margin-right: 30px;
          border-bottom: 4px solid transparent;
          cursor: pointer;

          &.active {
            position: relative;
            border-color: var(--main-color);
            z-index: 2;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

/* Mobile */

.rootMobile {
  > .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  > .list {
    position: relative;
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      flex-direction: column;
      padding: 15px;
      border-radius: 10px;
      margin-bottom: 15px;

      .icon {
        display: block;
        svg {
          width: 32px;
          height: 32px;
        }
      }
      &:nth-child(1) {
        background: var(--main-color);
      }
      &:nth-child(2) {
        background: var(--main-color);
      }
      &:nth-child(3) {
        background: #fff;
        .count {
          color: var(--main-color-title);
        }

        .title {
          color: var(--main-color-title);
        }
        svg {
          path {
            fill: var(--main-color);
          }
        }
      }
      &:nth-child(4) {
        background: #fff;

        .count {
          color: var(--main-color-title);
        }

        .title {
          color: var(--main-color-title);
        }
        svg {
          path {
            fill: var(--main-color);
          }
        }
      }

      .count {
        font-size: 22px;
        font-weight: 600;
        margin: 10px 0;
        color: #fff;
      }

      .title {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
      }
    }
  }

  .chart {
    padding: 15px 5px;
    border-radius: 10px;
    background: #fff;

    .switch {
      margin-bottom: 20px;

      .list {
        position: relative;
        display: flex;

        &::after {
          content: '';
          position: absolute;
          display: block;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #f4f4f4;
        }

        .item {
          font-size: 18px;
          padding-bottom: 12px;
          margin-right: 20px;
          border-bottom: 2px solid transparent;
          cursor: pointer;

          &.active {
            position: relative;
            border-color: var(--main-color);
            z-index: 2;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
