.root {
  overflow: hidden;
  border-radius: 10px;

  .error {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    align-items: center;
    margin-top: 25px;
    color: #f2af03;
    svg {
      margin-right: 15px;
    }
  }

  .wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 25px;
    justify-content: space-between;
    border-radius: 10px;

    &.orange {
      background: #f2af03;

      .block {
        border-color: rgba(255, 255, 255, 0.2);

        .value {
          color: #fff;
        }

        .text {
          color: #fff;
        }
      }
    }

    &.blue {
      background: #33a9dc;

      .block {
        border-color: rgba(255, 255, 255, 0.2);

        .value {
          color: #fff;
        }

        .text {
          color: #fff;
        }
      }
    }
  }

  .block {
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #f4f4f4;

    &:last-child {
      padding-right: 0;
      margin-right: 0;
      border: none;
    }

    .value {
      font-size: 36px;
      color: var(--main-color-title);
    }

    .text {
      font-size: 10px;
      text-transform: uppercase;
    }
  }
}

/* Mobile */

.rootMobile {
  overflow: hidden;
  border-radius: 10px;

  .error {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    align-items: center;
    margin-top: 25px;
    color: #f2af03;
    svg {
      margin-right: 15px;
    }
  }

  .wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 15px;
    justify-content: space-between;
    border-radius: 10px;

    &.orange {
      background: #f2af03;

      .block {
        border-color: rgba(255, 255, 255, 0.2);

        .value {
          color: #fff;
        }

        .text {
          color: #fff;
        }
      }
    }

    &.blue {
      background: #33a9dc;

      .block {
        border-color: rgba(255, 255, 255, 0.2);

        .value {
          color: #fff;
        }

        .text {
          color: #fff;
        }
      }
    }
  }

  .block {
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #f4f4f4;

    &:last-child {
      padding-right: 0;
      margin-right: 0;
      border: none;
    }

    .value {
      font-size: 24px;
      color: var(--main-color-title);
    }

    .text {
      font-size: 10px;
      text-transform: uppercase;
    }
  }
}
