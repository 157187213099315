.root {
  .title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .switch {
    margin-bottom: 30px;
  }
}

/* Mobile */

.rootMobile {
  .title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .switch {
    margin-bottom: 20px;
  }
}
