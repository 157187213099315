.root {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background: #f2f2f2;
  }

  .wrap {
    > .top {
      display: flex;
      justify-content: space-between;
      padding: 30px 0 20px 0;
      margin-bottom: 20px;
      border-bottom: 1px solid #f4f4f4;

      .logo {
        min-width: 165px;

        img {
          width: 100%;
        }
      }
    }

    .form {
      display: flex;
      align-items: center;
      button {
        border: none;
        background: none;
      }

      .notif {
        position: relative;
        margin-right: 60px;
        .count {
          display: flex;
          position: absolute;
          top: -10px;
          right: -20px;
          width: 20px;
          height: 15px;
          font-size: 10px;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: #ab0000;
          color: #fff;
        }
      }
      .search {
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        border: 1px solid var(--main-color);
        border-radius: 10px;
        background: #cce9f6;
        cursor: pointer;

        svg {
          path {
            fill: var(--main-color);
          }
        }
      }

      .add {
        position: relative;
        min-height: 40px;
        font-size: 14px;
        font-weight: 700;
        padding: 0 16px 0 40px;
        margin-right: 40px;
        border-radius: 10px;
        background: var(--main-color);
        color: #fff;
        cursor: pointer;

        span {
          position: relative;
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);

          &::after {
            position: absolute;
            content: '';
            display: block;
            top: 50%;
            left: 50%;
            width: 2px;
            height: 14px;
            transform: translate(-50%, -50%);
            background: #fff;
          }
          &::before {
            position: absolute;
            content: '';
            display: block;
            top: 50%;
            left: 50%;
            width: 14px;
            height: 2px;
            transform: translate(-50%, -50%);
            background: #fff;
          }
        }
      }

      .user {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        .drop {
          display: block;
          margin-right: 20px;
          line-height: 0;
          transition: all 0.3s ease-in-out;

          &.active {
            transform: rotate(180deg);
          }
        }

        .img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          overflow: hidden;
        }

        .menu {
          position: absolute;
          top: 115%;
          right: 0;
          width: 120px;
          padding: 15px;
          opacity: 0;
          visibility: hidden;
          background: #fff;
          transition: all 0.3s ease-in-out;
          box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          z-index: 6;
          &.active {
            opacity: 1;
            visibility: visible;
          }

          .link {
            display: block;
            font-size: 16px;
            margin-bottom: 15px;
            color: var(--main-color-text);
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }

          button {
            cursor: pointer;
          }
        }

        .hamburg {
          position: relative;
          display: flex;
          width: 35px;
          height: 35px;
          margin-left: 30px;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .top {
            position: absolute;
            top: 10px;
            width: 100%;
            height: 3px;
            margin-bottom: 7px;
            background: var(--main-color);
          }

          .middle {
            position: absolute;
            top: 50%;
            width: 100%;
            height: 3px;
            transform: translateY(-50%);
            background: var(--main-color);
          }

          .bottom {
            position: absolute;
            bottom: 10px;
            width: 100%;
            height: 3px;
            background: var(--main-color);
          }
        }
      }
    }
  }
}
/* Mobile */
.rootMobile {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background: #f2f2f2;
  }

  .wrap {
    > .top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;

      .logo {
        margin-bottom: 20px;
      }
    }

    .form {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      > a {
        margin-right: auto;
      }
      button {
        border: none;
        background: none;
      }

      .notif {
        position: relative;
        margin-right: 25px;
        .count {
          display: flex;
          position: absolute;
          top: -10px;
          right: -20px;
          width: 20px;
          height: 15px;
          font-size: 10px;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: #ab0000;
          color: #fff;
        }
      }
      .search {
        display: flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border: 1px solid var(--main-color);
        border-radius: 10px;
        background: #cce9f6;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
          path {
            fill: var(--main-color);
          }
        }
      }

      .add {
        position: relative;
        min-height: 30px;
        font-size: 10px;
        font-weight: 700;
        padding: 0 16px 0 25px;
        margin-right: auto;
        border-radius: 10px;
        background: var(--main-color);
        color: #fff;
        cursor: pointer;

        span {
          position: relative;
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);

          &::after {
            position: absolute;
            content: '';
            display: block;
            top: 50%;
            left: 50%;
            width: 2px;
            height: 10px;
            transform: translate(-50%, -50%);
            background: #fff;
          }
          &::before {
            position: absolute;
            content: '';
            display: block;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 2px;
            transform: translate(-50%, -50%);
            background: #fff;
          }
        }
      }

      .user {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        .drop {
          display: block;
          margin-right: 10px;
          line-height: 0;
          transition: all 0.3s ease-in-out;

          &.active {
            transform: rotate(180deg);
          }
        }

        .img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          overflow: hidden;
        }

        .menu {
          position: absolute;
          top: 115%;
          right: 0;
          width: 120px;
          padding: 15px;
          opacity: 0;
          visibility: hidden;
          background: #fff;
          transition: all 0.3s ease-in-out;
          box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          z-index: 6;
          &.active {
            opacity: 1;
            visibility: visible;
          }

          .link {
            display: block;
            font-size: 16px;
            margin-bottom: 15px;
            color: var(--main-color-text);
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }

          button {
            cursor: pointer;
          }
        }

        .hamburg {
          position: relative;
          display: flex;
          width: 25px;
          height: 25px;
          margin-left: 30px;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .top {
            position: absolute;
            top: 2px;
            width: 100%;
            height: 3px;
            transition: all 0.3s ease-in-out;
            background: var(--main-color);
          }

          .middle {
            position: absolute;
            top: 50%;
            width: 100%;
            height: 3px;
            transform: translateY(-50%);
            transition: all 0.1s ease-out;
            background: var(--main-color);
          }

          .bottom {
            position: absolute;
            bottom: 2px;
            width: 100%;
            height: 3px;
            transition: all 0.3s ease-in-out;
            background: var(--main-color);
          }

          &.active {
            .top {
              top: 50%;
              transform: translateY(-50%) rotate(45deg);
            }
            .middle {
              opacity: 0;
            }
            .bottom {
              top: 50%;
              transform: translateY(-50%) rotate(-45deg);
            }
          }
        }
      }
    }
  }
}
