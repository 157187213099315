.root {
  .top {
    margin-bottom: 40px;

    .filter {
      max-width: 310px;
      width: 100%;
      flex: none;
    }
    .estate {
      display: block;
    }
  }

  .switch {
    margin-bottom: 30px;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
  }
}

/* Mobile */

.rootMobile {
  max-width: 480px;
  width: 100%;

  .top {
    margin-bottom: 40px;

    .filter {
      max-width: 310px;
      width: 100%;
      flex: none;
    }
    .estate {
      display: block;
    }
  }

  .switch {
    margin-bottom: 30px;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
  }
}
